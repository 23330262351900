import { Role } from "src/app/core/models/role";
import { RouteInfo } from "../sidebar.metadata";

export const MANAGEMENTEXECUTIVEROUTING: RouteInfo[] = [
  {
    path: "/dashboard/main",
    title: "MENUITEMS.DASHBOARD2.TEXT",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    iconSidebar: "dashboard.svg",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [Role.MANAGEMENTEXECUTIVE],
    submenu: [],
  },
];
