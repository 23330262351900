import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { PatientService } from "src/app/admin/patients/allpatients/patient.service";
import { MePort } from "src/app/core/models/auth.interface";
import { Role } from "src/app/core/models/role";
import { ValidationPattern } from "src/app/core/models/validation-pattern";
import { AdminCentralService } from "src/app/core/service/admin-central.service";
import { AdminService } from "src/app/core/service/admin.service";
import { AuthService } from "src/app/core/service/auth.service";
import { DoctorService } from "src/app/core/service/doctor.service";
import { ErrorService } from "src/app/core/service/error.service";
import { HttpService } from "src/app/core/service/http.service";
import { ManagementExecutiveService } from "src/app/core/service/management-executive.service";
import { SuperAdminService } from "src/app/core/service/super-admin.service";

@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: ["./setting.component.scss"],
})
export class SettingComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly superAdminService: SuperAdminService,
    private readonly adminService: AdminService,
    private readonly managementExecutiveService: ManagementExecutiveService,
    private readonly doctorService: DoctorService,
    private readonly adminCentralService: AdminCentralService,
    private readonly errorService: ErrorService,
    private readonly httpService: HttpService
  ) { }

  me: MePort;
  passwordPattern = ValidationPattern.passwordPattern;
  password: string = '';

  data = {
    name: '',
    email: '',
    date_of_birth: '',
    phone_number: '',
  };

  todayDate = new Date();

  async ngOnInit() {
    this.getMe();
  }

  async getMe() {
    this.me = await this.authService.getMe();
    if (this.me) this.loadData();
  }

  clickBack() {
    if (this.me.role === Role.DOCTOR) this.router.navigate(["/dashboard-cabang"]);
    if (this.me.role === Role.ADMIN) this.router.navigate(["/janji-temu/lihat-janji"]);
    if (this.me.role === Role.SUPERADMIN || this.me.role === Role.MANAGEMENTEXECUTIVE) this.router.navigate(["/dashboard/main"]);
    if (this.me.role === Role.ADMINCENTRAL) this.router.navigate(["/janji-temu/lihat-janji"]);
  }

  loadData() {
    if (this.me.role === Role.DOCTOR) this.doctorService.detailDoctor(this.me.user_id).subscribe(res => {
      this.data = {
        name: res.data?.name,
        email: res.data?.email,
        date_of_birth: res.data?.date_of_birth,
        phone_number: res.data?.phone_number,
      };
    });
    if (this.me.role === Role.ADMIN) this.adminService.detailAdmin(this.me.user_id).subscribe(res => {
      this.data = {
        name: res.data?.name,
        email: res.data?.email,
        date_of_birth: res.data?.date_of_birth,
        phone_number: res.data?.phone_number,
      };
    });
    if (this.me.role === Role.SUPERADMIN) this.superAdminService.detailSuperAdmin(this.me.user_id).subscribe(res => {
      this.data = {
        name: res.data?.name,
        email: res.data?.email,
        date_of_birth: res.data?.date_of_birth,
        phone_number: res.data?.phone_number,
      };
    });
    if (this.me.role === Role.MANAGEMENTEXECUTIVE) this.managementExecutiveService.detailManagementExecutive(this.me.user_id).subscribe(res => {
      this.data = {
        name: res.data?.name,
        email: res.data?.email,
        date_of_birth: res.data?.date_of_birth,
        phone_number: res.data?.phone_number,
      };
    });
    if (this.me.role === Role.ADMINCENTRAL) this.adminCentralService.detailAdminCentral(this.me.user_id).subscribe(res => {
      this.data = {
        name: res.data?.name,
        email: res.data?.email,
        date_of_birth: res.data?.date_of_birth,
        phone_number: res.data?.phone_number,
      };
    });
  }

  updatePassword() {
    if (!this.password.match(this.passwordPattern)) return this.errorService.handleError('Minimal 6 karakter yang berisikan huruf kapital, dan huruf kecil.');
    this.authService.updatePassword({ password: this.password }).subscribe(res => {
      this.httpService.SuccessProcess('Sukses update password');
      this.resetPassword();
    });
  }

  resetPassword() {
    this.password = '';
  }

}
