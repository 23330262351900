<div class="row mr-0 w-100">
  <div class="col-md-12 text-right float-right text-end mt-2 mb-3">
    <button (click)="dialogRef.close()" type="button" class="btn btn-danger btn-sm mr-1 mb-2 me-1">
      <mat-icon class="text-light">close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
        <preview-template class="mt-5 mb-5" [formContainer]="data">
        </preview-template>
      </div>
    </div>
  </div>
</div>
