import { Role } from "src/app/core/models/role";
import { RouteInfo } from "../sidebar.metadata";

export const ADMINCENTRALROUTING: RouteInfo[] = [
  {
    path: "",
    title: "MENUITEMS.JANJITEMU.TEXT",
    iconType: "material-icons-two-tone",
    icon: "date_range",
    iconSidebar: "janji-temu.svg",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [Role.ADMINCENTRAL],
    submenu: [
      {
        path: "/janji-temu/lihat-janji",
        title: "MENUITEMS.JANJITEMU.LIST.VIEW",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.ADMINCENTRAL],
        submenu: [],
      },
      {
        path: "/janji-temu/status-janji",
        title: "MENUITEMS.JANJITEMU.LIST.STATUS",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.ADMINCENTRAL],
        submenu: [],
      },
      {
        path: "/antrian",
        title: "MENUITEMS.JANJITEMU.LIST.ANTRIAN",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.ADMINCENTRAL],
        submenu: [],
      },
    ],
  },
  // pasient
  {
    path: "",
    title: "MENUITEMS.PATIENTS.TEXT",
    iconType: "material-icons-two-tone",
    icon: "airline_seat_individual_suite",
    iconSidebar: "patient.svg",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [Role.ADMINCENTRAL],
    submenu: [
      {
        path: "/patients/all-patients",
        title: "MENUITEMS.PATIENTS.LIST.ALL-PATIENT",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.ADMINCENTRAL],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.ADMIN-MANAGEMENT.TEXT",
    iconType: "material-icons-two-tone",
    icon: "article",
    iconSidebar: "admin.svg",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [Role.ADMINCENTRAL],
    submenu: [
      {
        path: "/poli/list-poli",
        title: "MENUITEMS.ADMIN-MANAGEMENT.LIST.POLI",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.ADMINCENTRAL],
        submenu: [],
      },
      {
        path: "/doctors/all-doctors",
        title: "MENUITEMS.ADMIN-MANAGEMENT.LIST.DOKTER",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.ADMINCENTRAL],
        submenu: [],
      },
      {
        path: "/jadwal-praktik",
        title: "MENUITEMS.ADMIN-MANAGEMENT.LIST.PRAKTIK",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.ADMINCENTRAL],
        submenu: [],
      },
    ],
  },
];
