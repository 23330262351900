import { Injectable } from "@angular/core";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "../models/filter.interface";

@Injectable()
export class AdminService {
  constructor(
    private httpService: HttpService
  ) { }

  listAdmin(params: Filter = null) {
    return this.httpService.Get(`/v1/admin/list`, params);
  }
  createAdmin(data: any) {
    return this.httpService.Post(`/v1/admin/create`, data);
  }
  updateAdmin(id: any, data: any) {
    return this.httpService.Put(`/v1/admin/update/${id}`, data);
  }
  deleteAdmin(id: any) {
    return this.httpService.Delete(`/v1/admin/delete`, { id: id });
  }
  detailAdmin(id: any) {
    return this.httpService.Get(`/v1/admin/detail/${id}`);
  }

}
