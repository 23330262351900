<section class="content">
  <div class="content-block cont">
    <div class="logo">
      <img (click)="redirectAppointment()" src="assets/images/banner/logo-kmnc.png" alt="logo-kmnc" class="logo-kmnc" />
      <mat-icon (click)="clickBack()" class="back">keyboard_arrow_left</mat-icon>
    </div>
    <div class="flex-pasien-identity">
      <div class="detail-patient">
        <app-personal-information-patient></app-personal-information-patient>
      </div>
      <div class="section-identitas">
        <div class="content-pasien">
          <form class="w-100">
            <div class="row">
              <div class="father-mom-title">Identitas Pasien</div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Nama</mat-label>
                  <input oninput="this.value = this.value?.toUpperCase()" matInput name="name"
                    [(ngModel)]="patient.name" />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>NIK</mat-label>
                  <input oninput="this.value = this.value?.toUpperCase()" matInput name="nik"
                    [(ngModel)]="patient.nik" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Tanggal Lahir</mat-label>
                  <input [max]="todayDate" matInput [matDatepicker]="pickerBirthDate" name="date_of_birth"
                    [(ngModel)]="patient.date_of_birth" />
                  <mat-datepicker-toggle matSuffix [for]="pickerBirthDate"></mat-datepicker-toggle>
                  <mat-datepicker #pickerBirthDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Jenis Kelamin</mat-label>
                  <mat-select name="gender" [(ngModel)]="patient.gender">
                    <mat-option [value]="'Pria'"> Pria </mat-option>
                    <mat-option [value]="'Wanita'"> Wanita </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Provinsi</mat-label>
                  <mat-select [(ngModel)]="provinceSelected" (selectionChange)="onChangeProvince($event)"
                    name="province_id">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Cari Provinsi"
                        (input)="searchProvince($event, 'patient')">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let province of searchProvinces" [value]="province">
                      {{ province.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Kabupaten/Kota</mat-label>
                  <mat-select [(ngModel)]="districtSelected" (selectionChange)="onChangeDistrict($event)"
                    name="district_id">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Cari Kota/Kabupaten"
                        (input)="searchDistrict($event, 'patient')">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let district of searchDistricts" [value]="district">
                      {{ district.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Kecamatan</mat-label>
                  <mat-select [(ngModel)]="subDistrictSelected" (selectionChange)="onChangeSubDistrict($event)"
                    name="sub_district_id">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Cari Kecamatan"
                        (input)="searchSubDistrict($event, 'patient')">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let subDistrict of searchSubDistricts" [value]="subDistrict">
                      {{ subDistrict.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Alamat</mat-label>
                  <input matInput name="street" [(ngModel)]="patient.address.street" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput name="email" [(ngModel)]="patient.email" />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>No. Telp</mat-label>
                  <input matInput type="number" name="phone_number" [(ngModel)]="patient.phone_number" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Penghasilan per bulan dari</mat-label>
                  <input matInput type="number" name="income_start" [(ngModel)]="
                      patient.additional?.finance?.income_monthly.start
                    " />
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Penghasilan per bulan sampai</mat-label>
                  <input matInput type="number" name="income_end" [(ngModel)]="
                      patient.additional?.finance?.income_monthly.end
                    " />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Pembiayaan</mat-label>
                  <input matInput name="financing" [(ngModel)]="patient.additional?.finance.financing" />
                </mat-form-field>
              </div>
            </div>

            <!-- Form for Ayah -->

            <div class="father-mom-title">Ayah</div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Nama</mat-label>
                  <input matInput name="fatherName" [(ngModel)]="patient.additional?.parent.father.name" />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Tanggal Lahir</mat-label>
                  <input matInput [max]="todayDate" name="father_date_of_birth" [(ngModel)]="
                      patient.additional?.parent.father.date_of_birth
                    " [matDatepicker]="fatherDatePicker" />
                  <mat-datepicker-toggle matSuffix [for]="fatherDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #fatherDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>No. JKN</mat-label>
                  <input matInput name="father_jkn_number" [(ngModel)]="patient.additional?.parent.father.jkn_number" />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>NIK</mat-label>
                  <input matInput type="number" name="father_nik" [(ngModel)]="patient.additional?.parent.father.nik" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Provinsi</mat-label>
                  <mat-select [(ngModel)]="provinceSelectedFather"
                    (selectionChange)="onChangeProvince($event, 'father')" name="father_province_id">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Cari Provinsi"
                        (input)="searchProvince($event, 'father')">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let province of searchProvincesFather" [value]="province">
                      {{ province.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Kabupaten/Kota</mat-label>
                  <mat-select [(ngModel)]="districtSelectedFather"
                    (selectionChange)="onChangeDistrict($event, 'father')" name="father_district_id">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Cari Kota/Kabupaten"
                        (input)="searchDistrict($event, 'father')">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let district of searchDistrictsFather" [value]="district">
                      {{ district.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Kecamatan</mat-label>
                  <mat-select [(ngModel)]="subDistrictSelectedFather"
                    (selectionChange)="onChangeSubDistrict($event, 'father')" name="father_sub_district_id">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Cari Kecamatan"
                        (input)="searchSubDistrict($event, 'father')">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let subDistrict of searchSubDistrictsFather" [value]="subDistrict">
                      {{ subDistrict.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Alamat</mat-label>
                  <input matInput name="father_street" [(ngModel)]="
                      patient.additional.parent.father.address.street
                    " />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Golongan Darah</mat-label>
                  <mat-select [(ngModel)]="patient.additional.parent.father.blood_type" name="father-golongan-darah">
                    <mat-option *ngFor="let resp of golonganDarahArray" [value]="resp">
                      {{ resp }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>No. Telp</mat-label>
                  <input type="number" name="father_phone_number"
                    [(ngModel)]="patient.additional.parent.father.phone_number" matInput />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Pendidikan</mat-label>
                  <mat-select name="father_education" [(ngModel)]="patient.additional.parent.father.education">
                    <mat-option *ngFor="let pend of pendidikanArray" [value]="pend">
                      {{ pend }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Pekerjaan</mat-label>
                  <input matInput [(ngModel)]="patient.additional.parent.father.job" name="father_job" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Faskes TK 1</mat-label>
                  <input matInput name="father_health_facility" [(ngModel)]="
                      patient.additional.parent.father.health_facility
                    " />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Faskes Rujukan</mat-label>
                  <input matInput name="father_referred_health_facility" [(ngModel)]="
                      patient.additional.parent.father.referred_health_facility
                    " />
                </mat-form-field>
              </div>
            </div>

            <!-- Form for Ibu -->

            <div class="father-mom-title">Ibu</div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Nama</mat-label>
                  <input matInput name="mother_name" [(ngModel)]="patient.additional.parent.mother.name" />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Tanggal Lahir</mat-label>
                  <input matInput [max]="todayDate" name="mother_date_of_birth"
                    [(ngModel)]="patient.additional.parent.mother.date_of_birth" [matDatepicker]="dateBirthMother" />
                  <mat-datepicker-toggle matSuffix [for]="dateBirthMother"></mat-datepicker-toggle>
                  <mat-datepicker #dateBirthMother></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>No. JKN</mat-label>
                  <input matInput name="mother_jkn_number" [(ngModel)]="patient.additional.parent.mother.jkn_number" />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>NIK</mat-label>
                  <input matInput name="mother_nik" [(ngModel)]="patient.additional.parent.mother.nik" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Provinsi</mat-label>
                  <mat-select [(ngModel)]="provinceSelectedMother"
                    (selectionChange)="onChangeProvince($event, 'mother')" name="mother_province_id">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Cari Provinsi"
                        (input)="searchProvince($event, 'mother')">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let province of searchProvincesMother" [value]="province">
                      {{ province.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Kabupaten/Kota</mat-label>
                  <mat-select [(ngModel)]="districtSelectedMother"
                    (selectionChange)="onChangeDistrict($event, 'mother')" name="mother_district_id">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Cari Kota/Kabupaten"
                        (input)="searchDistrict($event, 'mother')">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let district of searchDistrictsMother" [value]="district">
                      {{ district.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Kecamatan</mat-label>
                  <mat-select [(ngModel)]="subDistrictSelectedMother"
                    (selectionChange)="onChangeSubDistrict($event, 'mother')" name="mother_sub_district_id">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Cari Kecamatan"
                        (input)="searchSubDistrict($event, 'mother')">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let subDistrict of searchSubDistrictsMother" [value]="subDistrict">
                      {{ subDistrict.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Alamat</mat-label>
                  <input matInput name="mother_street" [(ngModel)]="
                      patient.additional.parent.mother.address.street
                    " />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Golongan Darah</mat-label>
                  <mat-select [(ngModel)]="patient.additional.parent.mother.blood_type" name="mother-golongan-darah">
                    <mat-option *ngFor="let resp of golonganDarahArray" [value]="resp">
                      {{ resp }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>No. Telp</mat-label>
                  <input type="number" name="mother_phone_number"
                    [(ngModel)]="patient.additional.parent.mother.phone_number" matInput />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Pendidikan</mat-label>
                  <mat-select name="mother_education" [(ngModel)]="patient.additional.parent.mother.education">
                    <mat-option *ngFor="let pend of pendidikanArray" [value]="pend">
                      {{ pend }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Pekerjaan</mat-label>
                  <input matInput [(ngModel)]="patient.additional.parent.mother.job" name="mother_job" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Faskes TK 1</mat-label>
                  <input matInput name="mother_health_facility" [(ngModel)]="
                      patient.additional.parent.mother.health_facility
                    " />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Faskes Rujukan</mat-label>
                  <input matInput name="mother_referred_health_facility" [(ngModel)]="
                      patient.additional.parent.mother.referred_health_facility
                    " />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-button-center">
              <div class="btn-update-patient" (click)="onSubmit()">
                Simpan Perubahan
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
