import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Filter } from '../models/filter.interface';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor(
    private httpService: HttpService
  ) { }

  listForm(params: Filter = null) {
    return this.httpService.Get(`/v1/form/list`, params);
  }
  createForm(data: any) {
    return this.httpService.Post(`/v1/form/create`, data);
  }
  updateForm(id: string, data: any) {
    return this.httpService.Put(`/v1/form/update/${id}`, data);
  }
  deleteForm(id: string) {
    return this.httpService.Delete(`/v1/form/delete`, { id: id });
  }
  detailForm(id: string) {
    return this.httpService.Get(`/v1/form/detail/${id}`);
  }

}
