import { Injectable } from "@angular/core";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "../models/filter.interface";

@Injectable()
export class ManagementExecutiveService extends UnsubscribeOnDestroyAdapter {
  constructor(
    private httpService: HttpService
  ) { super(); }

  listManagementExecutive(params: Filter = null) {
    return this.httpService.Get(`/v1/management-executive/list`, params);
  }
  createManagementExecutive(data: any) {
    return this.httpService.Post(`/v1/management-executive/create`, data);
  }
  updateManagementExecutive(id: string, data: any) {
    return this.httpService.Put(`/v1/management-executive/update/${id}`, data);
  }
  deleteManagementExecutive(id: string) {
    return this.httpService.Delete(`/v1/management-executive/delete`, { id: id });
  }
  detailManagementExecutive(id: string) {
    return this.httpService.Get(`/v1/management-executive/detail/${id}`);
  }

}
