<section class="content">
  <div class="content-block cont">
    <div class="logo">
      <img (click)="clickBack()" src="assets/images/banner/logo-kmnc.png" alt="logo-kmnc" class="logo-kmnc" />
      <mat-icon (click)="clickBack()" class="back">keyboard_arrow_left</mat-icon>
    </div>
    <div class="flex-pasien-history">
      <div class="detail-patient">
        <app-personal-information-patient></app-personal-information-patient>
      </div>
      <div class="section-pemeriksaan">
        <div class="content-pemeriksaan">
          <div class="content-card">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3" *ngFor="let form of ehrForms; let index = index">
                <div class="card-height" class="cards1">
                  <div class="title-1" (click)="viewFormEHR(form)">
                    {{ form.nama_poli | slice : 0 : 20 }}
                    <span *ngIf="form.nama_poli >= 20">...</span>
                  </div>
                  <div class="title-2">
                    {{ form.tanggal_pemeriksaan | date : "d MMMM y" }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
