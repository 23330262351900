// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://api-staging.sim.kmnc.co.id/users",
};
const config = {
  CMS_URL: "https://cms.temanibu.ai",
  CMS_FOLDER: "15ed80f0-04ee-471c-ad81-fed3d353ed8e",
  CMS_STATIC_TOKEN: "CJyb4_7YnGC3e4UzuJl67bdIZEp9q3E7",
};
export const appConfig = {
  ...config,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
