import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormGeneratorRoutingModule } from "./form-generator-routing.module";
import { FormGeneratorComponent } from "./form-generator.component";
import { FormDetailComponent } from "./form-detail/form-detail.component";
import { MatIconModule } from "@angular/material/icon";
import { FormPreviewComponent } from "./form-preview/form-preview.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ICDXService } from "src/app/core/service/icd-x.service";
import { HttpClientModule } from "@angular/common/http";
import { AswCalculationModule } from "@asoftwareworld/form-builder/form-control/calculation";
import { AswCheckboxModule } from "@asoftwareworld/form-builder/form-control/checkbox";
import { AswDatepickerModule } from "@asoftwareworld/form-builder/form-control/datepicker";
import { AswHeaderModule } from "@asoftwareworld/form-builder/form-control/header";
import { AswMultiSelectModule } from "@asoftwareworld/form-builder/form-control/multi-select";
import { AswNumberModule } from "@asoftwareworld/form-builder/form-control/number";
import { AswRadioButtonModule } from "@asoftwareworld/form-builder/form-control/radio-button";
import { AswSelectModule } from "@asoftwareworld/form-builder/form-control/select";
import { AswTextareaModule } from "@asoftwareworld/form-builder/form-control/textarea";
import { AswTextFieldModule } from "@asoftwareworld/form-builder/form-control/textfield";
import { PreviewTemplate } from "./form-preview/element/preview-template";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { RoleService } from "src/app/core/service/role.service";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AswConfirmDialogModule } from "@asoftwareworld/form-builder/form-control/confirm-dialog";
import { AswSharedDialogModule } from "@asoftwareworld/form-builder/form-control/core";
import { AswJsonPreviewDialogModule } from "@asoftwareworld/form-builder/form-control/json-preview-dialog";
import { FormElementICDXComponent } from "./form-elements/icdx";
import { FormBuilder } from "./form-builder/form-builder";
import { FormUpdateComponent } from "./form-update/form-update.component";
import { ItemPackageService } from "src/app/core/service/item-package.service";
import { FormElementItemPackageComponent } from "./form-elements/item-package/item-package.component";

@NgModule({
  declarations: [
    FormGeneratorComponent,
    FormDetailComponent,
    FormPreviewComponent,
    PreviewTemplate,
    FormBuilder,
    FormElementICDXComponent,
    FormUpdateComponent,
    FormElementItemPackageComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FormGeneratorRoutingModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AswCheckboxModule,
    AswDatepickerModule,
    AswHeaderModule,
    AswMultiSelectModule,
    AswSelectModule,
    AswTextareaModule,
    AswTextFieldModule,
    AswRadioButtonModule,
    AswNumberModule,
    AswCalculationModule,
    NgxMatSelectSearchModule,
    MatDialogModule,
    ClipboardModule,
    AswJsonPreviewDialogModule,
    DragDropModule,
    MatExpansionModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatTooltipModule,
    AswConfirmDialogModule,
    MatButtonModule,
    AswSharedDialogModule,
  ],
  providers: [DatePipe, ICDXService, RoleService, ItemPackageService],
  exports: [
    FormGeneratorComponent,
    FormDetailComponent,
    FormPreviewComponent,
    PreviewTemplate,
    FormBuilder,
    FormElementICDXComponent,
    FormElementItemPackageComponent,
  ],
})
export class FormGeneratorModule {}
