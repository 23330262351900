import { Injectable } from "@angular/core";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "../models/filter.interface";

@Injectable()
export class PoliService extends UnsubscribeOnDestroyAdapter {
  constructor(
    private httpService: HttpService
  ) { super(); }

  createPoli(data: any) {
    return this.httpService.Post(`/v1/specialist/create`, data);
  }
  updatePoli(id: any, data: any) {
    return this.httpService.Put(`/v1/specialist/update/${id}`, data);
  }
  deletePoli(id: any) {
    return this.httpService.Delete(`/v1/specialist/delete`, { id: id });
  }
  detailPoli(id: any) {
    return this.httpService.Get(`/v1/specialist/detail/${id}`);
  }

  listPoli(params: Filter = null) {
    return this.httpService.Get(`/v1/specialist/list`, params);
  }

}
