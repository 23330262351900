import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AswConfirmDialog } from '@asoftwareworld/form-builder/form-control/confirm-dialog';
import { Constants } from '@asoftwareworld/form-builder/form-control/core';
import { ICDXControl } from './icd-x-control';
import { ICDX } from 'src/app/core/models/icd-x.interface';
import { ICDXService } from 'src/app/core/service/icd-x.service';
import { Overlay } from '@angular/cdk/overlay';

@Component({
    selector: 'form-element-icdx',
    templateUrl: './icd-x.component.html',
})
export class FormElementICDXComponent implements OnInit {
    constants: any = Constants;

    @Input() control: ICDXControl = null;

    @Input() controlIndex!: number;
    @Input() isPreviewTemplate = false;

    @Output() multiSelectDeleteEvent = new EventEmitter<number>();
    @Output() selectionChange = new EventEmitter<ICDXControl>();
    @Output() duplicateControl = new EventEmitter<ICDXControl>();

    icdXItems: ICDX[];

    ngOnInit(): void {
        this.listICDX('');
    }

    constructor(
        public dialog: MatDialog,
        private icdXService: ICDXService,
        private overlay: Overlay,
    ) { }

    deleteICDXDialog(control: ICDXControl, controlIndex: number): void {
        control.options = this.icdXItems.map(icdX => {
            return { value: icdX.diagnosis, key: icdX.code, isChecked: false };
        });
        const dialogRef = this.dialog.open(AswConfirmDialog, {
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            width: '350px',
            data: { name: control.controlType, message: this.constants.messages.waringMessage }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                this.multiSelectDeleteEvent.emit(controlIndex);
            }
        });
    }

    onSelectionChange(control: ICDXControl): void {
        control.options = this.icdXItems.map(icdX => {
            return { value: icdX.diagnosis, key: icdX.code, isChecked: false };
        });
        control.options.forEach(element => {
            element.isChecked = control.value?.includes(element?.key) ? true : false;
        });
        this.selectionChange.emit(control);
    }

    duplicateICDXControl(control: ICDXControl): void {
        control.options = this.icdXItems.map(icdX => {
            return { value: icdX.diagnosis, key: icdX.code, isChecked: false };
        });
        this.duplicateControl.emit(control);
    }

    listICDX(keyword: string) {
        const params = {
            page: 1,
            limit: 1000000,
            q: keyword,
            sort: 'diagnosis',
            dir: 'asc',
        };
        this.icdXService.listICDX(params).subscribe((res: any) => {
            this.icdXItems = res.data.list;
        })
    }

    searchICDX(event: any) {
        const input = event.target.value;
        const filterValue = input.toLowerCase();
        this.listICDX(filterValue);
    }

}
