import { NgModule } from "@angular/core";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { ErrorInterceptor } from "./core/interceptor/error.interceptor";
import { JwtInterceptor } from "./core/interceptor/jwt.interceptor";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ClickOutsideModule } from "ng-click-outside";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";

import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { NgScrollbarModule } from "ngx-scrollbar";
import { DashboardCabangComponent } from "./admin/dashboard/cabang/dashboard-cabang.component";
import { DetailPatientModule } from "./admin/patients/allpatients/detail-patient/detail-patient.module";
import { DatePipe } from "@angular/common";
import { AppointmentService } from "./admin/appointment/viewappointment/appointment.service";
import { PatientService } from "./admin/patients/allpatients/patient.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from "@angular/material/sort";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BranchService } from "./core/service/branch.service";
import { AdminService } from "./core/service/admin.service";
import { DoctorService } from "./core/service/doctor.service";
import { PoliService } from "./core/service/poli.service";
import { DoctorScheduleService } from "./admin/doctor-schedule/doctor-schedule.service";
import { MAT_DATE_FORMATS, MatNativeDateModule } from "@angular/material/core";
import { MomentDateModule } from "@angular/material-moment-adapter";
import { SettingComponent } from "./layout/setting/setting.component";
import { ComponentsModule } from "./shared/components/components.module";
import { SuperAdminService } from "./core/service/super-admin.service";
import { ManagementExecutiveService } from "./core/service/management-executive.service";
import { AdminCentralService } from "./core/service/admin-central.service";
import { TransactionService } from "./core/service/transaction.service";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    SettingComponent,
    DashboardCabangComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgScrollbarModule,
    ClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LoadingBarRouterModule,
    CoreModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSortModule,
    MatToolbarModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatNativeDateModule,
    MomentDateModule,
    DetailPatientModule,
    ComponentsModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DoctorService,
    AdminService,
    DatePipe,
    AppointmentService,
    PatientService,
    BranchService,
    PoliService,
    DoctorScheduleService,
    SuperAdminService,
    ManagementExecutiveService,
    AdminCentralService,
    TransactionService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
