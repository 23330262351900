<nav #navbar class="navbar active">
  <div class="container-fluid nav-flex">
    <div
      class="collapse navbar-collapse navbar-custom"
      [ngClass]="isNavbarCollapsed ? '' : 'show'"
    >
      <div class="left-header">
        <button
          mat-button
          *ngIf="!routeDetailPatient"
          (click)="mobileMenuSidebarOpen($event, 'overlay-open')"
        >
          <i id="menu" class="material-icons-outlined icon-left"> menu </i>
        </button>
        <div class="btn-back" *ngIf="routeDetailPatient" (click)="gotoBack()">
          <mat-icon class="icon-left">keyboard_arrow_left</mat-icon>
        </div>
        <div class="left-cabang">{{ branchName }}</div>
      </div>

      <div class="title-cabang">{{ branchName }}</div>
      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item user_profile">
          <button
            mat-button
            [matMenuTriggerFor]="profilemenu"
            class="btn-dropdown-profile"
          >
            <div class="chip dropdown-toggle" ngbDropdownToggle class="">
              <div class="chip-profile">
                <img
                  src="{{ getImage(userImg) }}"
                  onerror="this.src='assets/images/icon-profile.svg'"
                  alt=""
                  class="img-admin"
                />
                <div class="chip-name">
                  <span class="names">{{ name }}</span>
                  <span class="role">{{ role }}</span>
                </div>
                <div>
                  <i class="fas fa-angle-down icon-arrow"></i>
                </div>
              </div>
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu">
                <div class="user_dw_menu">
                  <button mat-menu-item (click)="settings()">
                    <app-feather-icons
                      [icon]="'settings'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >Setting
                  </button>
                  <button mat-menu-item (click)="logout()">
                    <app-feather-icons
                      [icon]="'log-out'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >Logout
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>
