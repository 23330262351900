import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Form, FormModel } from "src/app/core/models/form.model";
import {
  DefaultResponse,
  ResponseData,
} from "src/app/core/models/response.interface";
import { Role } from "src/app/core/models/role";
import { BranchService } from "src/app/core/service/branch.service";
import { FormService } from "src/app/core/service/form.service";
import { RoleService } from "src/app/core/service/role.service";

@Component({
  selector: "app-form-update",
  templateUrl: "./form-update.component.html",
  styleUrls: ["./form-update.component.scss"],
})
export class FormUpdateComponent implements OnInit {
  form = FormModel;
  branchs: any;
  roles: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private branchService: BranchService,
    private roleService: RoleService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.detailForm();
    this.getRoles();
    this.getBranchs();
  }

  detailForm() {
    const id = this.activatedRoute.snapshot.paramMap.get("id");
    this.formService.detailForm(id).subscribe((response: any) => {
      this.form = response.data;
    });
  }

  clickBack() {
    this.router.navigate(["/form/list-form"]);
  }
  getBranchs() {
    const params = {
      page: 1,
      limit: 100,
    };
    this.branchService
      .listBranch(params)
      .subscribe((res: DefaultResponse<ResponseData>) => {
        if (res.data.total_data > 0) {
          this.branchs = res.data.list;
        }
      });
  }

  getRoles() {
    const roles: string[] = [
      Role.ADMIN,
      Role.ADMINCENTRAL,
      Role.DOCTOR,
      Role.PATIENT,
    ];
    const params = {
      page: 1,
      limit: 100,
      role_list: roles.join(","),
    };
    this.roleService
      .listRole(params)
      .subscribe((res: DefaultResponse<ResponseData>) => {
        if (res.data.total_data > 0) {
          this.roles = res.data.list;
        }
      });
  }
}
