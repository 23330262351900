import { Injectable } from "@angular/core";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "../models/filter.interface";

@Injectable()
export class DoctorService {
  constructor(
    private httpService: HttpService
  ) { }

  listDoctor(params: Filter = null) {
    return this.httpService.Get(`/v1/doctor/list`, params);
  }
  createDoctor(data: any) {
    return this.httpService.Post(`/v1/doctor/create`, data);
  }
  updateDoctor(id: any, data: any) {
    return this.httpService.Put(`/v1/doctor/update/${id}`, data);
  }
  deleteDoctor(id: any) {
    return this.httpService.Delete(`/v1/doctor/delete`, { id: id });
  }
  detailDoctor(id: any) {
    return this.httpService.Get(`/v1/doctor/detail/${id}`);
  }

}
