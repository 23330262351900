import { Injectable } from "@angular/core";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "../models/filter.interface";

@Injectable()
export class RoleService extends UnsubscribeOnDestroyAdapter {
  constructor(
    private httpService: HttpService
  ) { super(); }

  listRole(params: Filter = null) {
    return this.httpService.Get(`/v1/role/list`, params);
  }
  createRole(data: any) {
    return this.httpService.Post(`/v1/role/create`, data);
  }
  updateRole(id: any, data: any) {
    return this.httpService.Put(`/v1/role/update/${id}`, data);
  }
  deleteRole(id: any) {
    return this.httpService.Delete(`/v1/role/delete`, { id: id });
  }
  detailRole(id: any) {
    return this.httpService.Get(`/v1/role/detail/${id}`);
  }

}
