import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from 'src/app/admin/appointment/viewappointment/appointment.service';
import { FormService } from 'src/app/core/service/form.service';
import { Appointment, AppointmentForm } from 'src/app/core/models/appointment.model';
import { MePort } from 'src/app/core/models/auth.interface';
import { AuthService } from '../../../../../core/service/auth.service';
import { RoleService } from 'src/app/core/service/role.service';
import { Filter } from 'src/app/core/models/filter.interface';
import { lastValueFrom } from 'rxjs';
import { Role } from 'src/app/core/models/role';

@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.scss']
})
export class PatientHistoryComponent implements OnInit {
  appointmentData: Appointment[];

  startVisitDate = '';
  endLastVisitDate = '';

  branchId = "";
  me: MePort;
  roleIds: string[];

  constructor(
    private router: Router,
    private formService: FormService,
    private appointmentService: AppointmentService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private roleService: RoleService,
  ) { }

  ngOnInit(): void {
    this.getMe();
  }

  getMe = async () => {
    this.me = await this.authService.getMe();
    this.branchId = this.me.position_branch?.id;
    this.loadData();
  }

  clickBack() {
    this.router.navigate(['janji-temu/lihat-janji']);
  }

  async loadData() {
    const patientId = this.activatedRoute.snapshot.paramMap.get("id");
    const roles = await this.getRoles();
    this.roleIds = roles?.map((role: any) => role.id);

    this.endLastVisitDate = this.endLastVisitDate ? this.endLastVisitDate : this.startVisitDate;

    const params: Filter = {
      page: 1,
      limit: 500,
      sort: 'date',
      dir: 'desc',
      branch_id: this.branchId,
      patient_id: patientId,
      start_last_visit_date: this.startVisitDate ? formatDate(this.startVisitDate, "yyyy-MM-dd", "en") : '',
      end_last_visit_date: this.endLastVisitDate ? formatDate(this.endLastVisitDate, "yyyy-MM-dd", "en") : '',
    };
    this.appointmentService
      .getAllAppointments(params)
      .subscribe(async (response: any) => {
        this.appointmentData = response.data.list;
        const forms = await this.getForms(this.roleIds);
        for (const appointment of this.appointmentData) {
          if (!appointment.forms) appointment.forms = forms.filter(form => {
            if (form.form.specialist_ids && form.form.specialist_ids.length > 0) return form.form.specialist_ids.includes(appointment.doctor_schedule?.specialist?.id)
            return true;
          });
          forms?.forEach((form: AppointmentForm) => {
            if (form.form?.specialist_ids.includes(appointment.doctor_schedule?.specialist?.id) && !appointment.forms.some((existingForm: AppointmentForm) => existingForm.form.id === form.form.id)) appointment.forms.push(form);
          });
        }
      });
  }

  validateRole(formAppointment: AppointmentForm[]): AppointmentForm[] {
    return formAppointment?.filter(formData =>
      this.roleIds.some(item => formData.form.role_ids.includes(item))
    ).sort((a: any, b: any) => b.is_filled - a.is_filled);
  }

  async getForms(roleIds: string[], specialistId?: string): Promise<AppointmentForm[]> {
    const dataList: AppointmentForm[] = [];
    const param: Filter = {
      page: 1,
      limit: 500,
      role_list: roleIds.join(','),
      branch_id: this.branchId,
      specialist_id: specialistId,
    };
    const resp = await lastValueFrom(this.formService.listForm(param))
    const items = resp.data.list;
    for (const item of items) {
      dataList.push({
        is_filled: false,
        updated_at: null,
        updated_by_name: '',
        form: item,
      });
    }
    return dataList;
  }

  resetDatePicker() {
    this.startVisitDate = "";
    this.endLastVisitDate = "";
    this.loadData();
  }

  async getRoles(): Promise<any[]> {
    const role: string = this.me.role;
    const roles: string[] = [role];
    if (this.me.role === Role.ADMINCENTRAL) roles.push(Role.ADMIN);
    const params = {
      page: 1,
      limit: 100,
      role_list: roles.join(','),
    };
    return lastValueFrom(this.roleService.listRole(params)).then(result => {
      return result.data.list;
    });
  }

}
