<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <ul class="breadcrumb">
            <li>
                <h4 class="page-title">{{title}}</h4>
            </li>
            <li class="breadcrumb-item" *ngFor="let item of items">{{item}}</li>
            <li class="breadcrumb-item active">{{active_item}}</li>
        </ul>
    </div>
</div>