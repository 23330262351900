<div class="modal-patient-ehr">
  <div class="container">
    <h5 class="text-center" mat-dialog-title>{{ data.nama_poli }}</h5>
    <div mat-dialog-content>
      <div class="table">
        <div class="row header-row">
          <div class="cell header-cell">Column</div>
          <div class="cell header-cell">Value</div>
        </div>

        <!-- Replace this part with Angular data binding -->
        <div class="row" *ngFor="let key of getObjectKeys(formData)">
          <div class="cell">{{ key }}</div>
          <div class="cell">{{ formData[key] ? formData[key] : '-' }}</div>
        </div>
      </div>
      <button mat-raised-button tabindex="-1" class="mt-2 close-button" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>
