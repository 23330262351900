import { Injectable } from "@angular/core";
import { HttpClient, } from "@angular/common/http";
import { lastValueFrom, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { EncryptionService } from "./encryption.service";
import { Router } from "@angular/router";
import { Role } from "../models/role";
import { DefaultResponse } from "../models/response.interface";
import { MePort } from "../models/auth.interface";
import { HttpService } from "./http.service";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private encryptService: EncryptionService,
    private httpService: HttpService
  ) { }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/auth/login`, {
        email,
        password,
      });
  }

  async getMe(): Promise<MePort> {
    const userLocal: string = localStorage.getItem('me');
    if (userLocal) {
      return JSON.parse(this.encryptService.decryptData(userLocal));
    };
    return lastValueFrom(this.http.get<DefaultResponse<MePort>>(`${environment.apiUrl}/v1/auth/me`, { responseType: 'json' }).pipe(
      map(res => {
        localStorage.setItem('me', this.encryptService.encryptData(JSON.stringify(res.data)));
        return res.data;
      }))
    ).catch((error) => { return error });
  }

  async handlingRouting(): Promise<void> {
    const user = await this.getMe();
    if (user.role === Role.DOCTOR) this.router.navigate(["/dashboard-cabang"]);
    if (user.role === Role.ADMIN) this.router.navigate(["/janji-temu/lihat-janji"]);
    if (user.role === Role.SUPERADMIN || user.role === Role.MANAGEMENTEXECUTIVE) this.router.navigate(["/dashboard/main"]);
    if (user.role === Role.ADMINCENTRAL) this.router.navigate(["/janji-temu/lihat-janji"]);
  }

  logout() {
    const clearToken = localStorage.clear();
    if (clearToken == null) this.router.navigate(['']);
    return of({ success: false });
  }

  getToken() {
    return localStorage.getItem('token');
  }

  updatePositionBranch(body) {
    return this.httpService.Put(`/v1/auth/update/position-branch`, body);
  }

  updatePassword(body) {
    return this.httpService.Put(`/v1/auth/update/password`, body);
  }

}
