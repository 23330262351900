export class Patient {
  additional: any;
  address: any;
  age: number;
  created_at: string;
  date_of_birth: string;
  deleted_at: string;
  email: string;
  gender: string;
  id: string;
  last_visit: string;
  medical_record: string;
  name: string;
  nik: string;
  phone_number: string;
  role: any;
  slug: string;
  total_visit: number;
  updated_at: string;
}

export const DetailPatientModel = {
  name: "",
  medical_record: "",
  address: {
    province_id: "",
    district_id: "",
    sub_district_id: "",
    street: "",
  },
  date_of_birth: "",
  nik: "",
  gender: "",
  email: "",
  phone_number: "",
  age: '',
  additional: {
    finance: {
      income_monthly: {
        start: 0,
        end: 0,
      },
      financing: "",
    },
    parent: {
      father: {
        name: "",
        date_of_birth: "",
        jkn_number: "",
        nik: "",
        address: {
          province: {
            id: ""
          },
          district: {
            id: ""
          },
          sub_district: {
            id: ""
          },
          street: "",
        },
        blood_type: "",
        phone_number: "",
        education: "",
        job: "",
        health_facility: "",
        referred_health_facility: "",
      },
      mother: {
        name: "",
        date_of_birth: "",
        nik: "",
        jkn_number: "",
        address: {
          province: {
            id: ""
          },
          district: {
            id: ""
          },
          sub_district: {
            id: ""
          },
          street: "",
        },
        blood_type: "",
        phone_number: "",
        education: "",
        job: "",
        health_facility: "",
        referred_health_facility: "",
      },
    },
  },
};
export class PatientResponse {
  data: any;
}
