import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "src/app/core/guard/auth.guard";
import { FormGeneratorComponent } from './form-generator.component';
import { Page404Component } from "src/app/authentication/page404/page404.component";
import { FormDetailComponent } from "./form-detail/form-detail.component";
import { FormUpdateComponent } from "./form-update/form-update.component";

const routes: Routes = [
  {
    path: 'create',
    canActivate: [AuthGuard],
    component: FormGeneratorComponent
  },
  {
    path: 'detail/:id',
    canActivate: [AuthGuard],
    component: FormDetailComponent
  },
  {
    path: 'update/:id',
    canActivate: [AuthGuard],
    component: FormUpdateComponent
  },
  {
    path: '**',
    component: Page404Component
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FormGeneratorRoutingModule { }
