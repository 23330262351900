import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AswConfirmDialog } from '@asoftwareworld/form-builder/form-control/confirm-dialog';
import { Constants } from '@asoftwareworld/form-builder/form-control/core';
import { ItemPackageControl } from './item-package-control';
import { ItemPackage } from 'src/app/core/models/item-package.interface';
import { ItemPackageService } from 'src/app/core/service/item-package.service';
import { Overlay } from '@angular/cdk/overlay';
@Component({
    selector: 'form-element-item-package',
    templateUrl: './item-package.component.html',
})
export class FormElementItemPackageComponent implements OnInit {
    constants: any = Constants;

    @Input() control: ItemPackageControl = null;

    @Input() controlIndex!: number;
    @Input() isPreviewTemplate = false;

    @Output() multiSelectDeleteEvent = new EventEmitter<number>();
    @Output() selectionChange = new EventEmitter<ItemPackageControl>();
    @Output() duplicateControl = new EventEmitter<ItemPackageControl>();

    itemPackages: ItemPackage[];

    ngOnInit(): void {
        this.listItemPackage('');
    }

    constructor(
        public dialog: MatDialog,
        private itemPackageService: ItemPackageService,
        private overlay: Overlay,
    ) { }

    deleteItemPackageDialog(control: ItemPackageControl, controlIndex: number): void {
        control.options = this.itemPackages.map(itemPackage => {
            return { value: itemPackage.name, key: itemPackage.code, isChecked: false };
        });
        const dialogRef = this.dialog.open(AswConfirmDialog, {
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            width: '350px',
            data: { name: control.controlType, message: this.constants.messages.waringMessage }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                this.multiSelectDeleteEvent.emit(controlIndex);
            }
        });
    }

    onSelectionChange(control: ItemPackageControl): void {
        control.options = this.itemPackages.map(itemPackage => {
            return { value: itemPackage.name, key: itemPackage.code, isChecked: false };
        });
        control.options.forEach(element => {
            element.isChecked = control.value?.includes(element?.key) ? true : false;
        });
        this.selectionChange.emit(control);
    }

    duplicateItemPackageControl(control: ItemPackageControl): void {
        control.options = this.itemPackages.map(itemPackage => {
            return { value: itemPackage.name, key: itemPackage.code, isChecked: false };
        });
        this.duplicateControl.emit(control);
    }

    listItemPackage(keyword: string) {
        const params = {
            page: 1,
            limit: 1000000,
            q: keyword,
            sort: 'name',
            dir: 'asc',
        };
        this.itemPackageService.listItemPackage(params).subscribe((res: any) => {
            this.itemPackages = res.data.list;
        })
    }

    searchItemPackage(event: any) {
        const input = event.target.value;
        const filterValue = input.toLowerCase();
        this.listItemPackage(filterValue);
    }

}
