import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/core/service/form.service';
import { MePort } from 'src/app/core/models/auth.interface';
import { AuthService } from '../../../../../core/service/auth.service';
import { RoleService } from 'src/app/core/service/role.service';
import { PatientService } from '../../patient.service';
import { lastValueFrom } from 'rxjs';
import { Appointment, AppointmentForm } from 'src/app/core/models/appointment.model';
import { Role } from 'src/app/core/models/role';
import { Filter } from 'src/app/core/models/filter.interface';
import { PatientEHRService } from 'src/app/core/service/patient-ehr.service';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { ModalPatientEHRComponent } from './modal/modal-patient-ehr.component';

@Component({
  selector: 'app-patient-ehr',
  templateUrl: './patient-ehr.component.html',
  styleUrls: ['./patient-ehr.component.scss']
})
export class PatientEHRComponent implements OnInit {
  appointmentData: Appointment[];

  branchId = "";
  me: MePort;
  roleIds: string[];


  ehrForms: any[] = [];

  constructor(
    private router: Router,
    private formService: FormService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private roleService: RoleService,
    private patientService: PatientService,
    private patientEHRService: PatientEHRService,
    private overlay: Overlay,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getMe();
  }

  getMe = async () => {
    this.me = await this.authService.getMe();
    this.branchId = this.me.position_branch?.id;
    this.loadData();
  }

  clickBack() {
    this.router.navigate(['janji-temu/lihat-janji']);
  }

  async loadData() {
    const patientId = this.activatedRoute.snapshot.paramMap.get("id");

    const patient = await lastValueFrom(this.patientService.getDetailPatient(patientId));
    if (patient.data) {
      const slug = patient.data.slug;
      const params: Filter = {
        page: 1,
        limit: 500,
        slug: slug,
      };
      await lastValueFrom(this.patientEHRService.listPatientEHR(params)).then(response => {
        if (response.data?.list) {
          response.data.list.forEach(item => {
            item.form?.forEach(dataForm => {
              if (typeof dataForm.tanggal_pemeriksaan !== 'string') dataForm.tanggal_pemeriksaan = '';
              this.ehrForms.push(dataForm);
            });
          })
        }
      });
    }

    // Sort the data in descending order based on 'tanggal_pemeriksaan'
    this.ehrForms.sort((a, b) => {
      const dateA = a.tanggal_pemeriksaan === '' ? 0 : new Date(a.tanggal_pemeriksaan).getTime();
      const dateB = b.tanggal_pemeriksaan === '' ? 0 : new Date(b.tanggal_pemeriksaan).getTime();
      return dateB - dateA;
    });
  }

  validateRole(formAppointment: AppointmentForm[]): AppointmentForm[] {
    return formAppointment?.filter(formData =>
      this.roleIds.some(item => formData.form.role_ids?.includes(item))
    ).sort((a: any, b: any) => b.is_filled - a.is_filled);
  }

  async getForms(roleIds: string[], specialistId?: string): Promise<AppointmentForm[]> {
    const dataList: AppointmentForm[] = [];
    const param: Filter = {
      page: 1,
      limit: 500,
      role_list: roleIds.join(','),
      branch_id: this.branchId,
      specialist_id: specialistId,
    };
    const resp = await lastValueFrom(this.formService.listForm(param))
    const items = resp.data.list;
    for (const item of items) {
      dataList.push({
        is_filled: false,
        updated_at: null,
        updated_by_name: '',
        form: item,
      });
    }
    return dataList;
  }

  async getRoles(): Promise<any[]> {
    const role: string = this.me.role;
    const roles: string[] = [role];
    if (this.me.role === Role.ADMINCENTRAL) roles.push(Role.ADMIN);
    const params = {
      page: 1,
      limit: 100,
      role_list: roles.join(','),
    };
    return lastValueFrom(this.roleService.listRole(params)).then(result => {
      return result.data.list;
    });
  }

  viewFormEHR(data: any) {
    this.dialog.open(ModalPatientEHRComponent, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: data,
    });
  }

}
