<section class="container container-1">
  <div class="left">
    <div class="flex-side">
      <div>
        <div class="title-side">Text Elements</div>
        <div class="row">
          <div *ngFor="let item of textElementControls">
            <div (click)="pushControl(item)" class="side-items">
              <mat-icon class="asw-icon mr-1 me-1">{{item.icon}}</mat-icon>
              <div class="side-item-title">{{item.displayName}}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="title-side">Date & Time Elements</div>

        <div class="row">
          <div *ngFor="let item of dateElementControls">
            <div (click)="pushControl(item)" class="side-items">
              <mat-icon class="asw-icon mr-1 me-1">{{item.icon}}</mat-icon>
              <div class="side-item-title">{{item.displayName}}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="title-side">Multi Elements</div>

        <div class="row">
          <div *ngFor="let item of multiElementControls">
            <div (click)="pushControl(item)" class="side-items">
              <mat-icon class="asw-icon mr-1 me-1">{{item.icon}}</mat-icon>
              <div class="side-item-title">{{item.displayName}}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>Other Controls</div>

        <div>
          <div class="row">
            <div *ngFor="let item of otherElementControls">
              <div (click)="pushControl(item)" class="side-items">
                <mat-icon class="asw-icon mr-1 me-1">{{item.icon}}</mat-icon>
                <div class="side-item-title">{{item.displayName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mid">
    <div>
      <div class="mid-form">
        <div class="nama">
          <div>Nama Form</div>
          <mat-form-field class="form-nama" appearance="outline">
            <input matInput [(ngModel)]="form.name" name="name" required placeholder="Masukkan Nama Form" />
            <mat-error ngshow="!false" class="hidden">
              Nama harus diisi.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="role">
          <div>Assigned to</div>
          <mat-form-field class="form-role" appearance="outline">
            <mat-select [(ngModel)]="form.role_ids" multiple required placeholder="Role Sebagai">
              <mat-option *ngFor="let item of roles" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
            <mat-error> Harus pilih minimal 1 role. </mat-error>
          </mat-form-field>
        </div>
        <div class="poli">
          <div>Akses Poli</div>
          <mat-form-field class="form-poli" appearance="outline">
            <mat-select multiple [(ngModel)]="form.specialist_ids" required placeholder="Pilih Cabang">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Cari Poli" (input)="searchSpecialist($event)"
                  [noEntriesFoundLabel]="'Poli tidak ada'">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let specialist of searchSpecialists" [value]="specialist.id">
                {{specialist.name}} ({{specialist.branch.name}})
              </mat-option>
            </mat-select>
            <mat-error> Harus pilih minimal 1 poli. </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="mid-form">
        <div class="required">
          <button type="button" class="btn btn-success w-100">
            <mat-slide-toggle [(ngModel)]="form.is_required" class="example-margin" [checked]="form.is_required">
              <span class="text-light">Apakah form harus di isi?</span>
            </mat-slide-toggle>
          </button>
        </div>
        <div class="required">
          <button type="button" class="btn btn-success w-100">
            <mat-slide-toggle [(ngModel)]="form.is_ttv" class="example-margin" [checked]="form.is_ttv">
              <span class="text-light">Apakah form TTV?</span>
            </mat-slide-toggle>
          </button>
        </div>
      </div>
    </div>
    <div class="desc-form">
      <div>Deskripsi</div>
      <mat-form-field class="desc-form-text" appearance="outline">
        <textarea matInput [(ngModel)]="form.description"></textarea>
      </mat-form-field>
    </div>

    <div class="form-place">
      <mat-card class="bg">
        <mat-card-content class="bg-border">
          <div class="element-empty" *ngIf="formElement.length <= 0">
            <img src="assets/images/add-element.svg" alt="" />
            <div class="title">Pilih elemen untuk ditambahkan</div>
            <div class="text">Tidak ada yang dipilih</div>
          </div>
          <div cdkDropList [cdkDropListData]="formElement" class="asw-list" (cdkDropListDropped)="drop($event)">
            <div class="row">
              <ng-container *ngFor="let control of formElement; let i = index">
                <div class="asw-box" [ngClass]="control.column" cdkDrag>
                  <ng-container [ngSwitch]="control.controlType">
                    <ng-container *ngSwitchCase="'header'">
                      <asw-header [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                        (headerUpdateEvent)="updatedControl($event)" (headerDeleteEvent)="deleteControl($event)"
                        (duplicateControl)="duplicateControl($event)">
                      </asw-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'textfield'">
                      <asw-textfield [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                        (textFieldUpdateEvent)="updatedControl($event)" (textFieldDeleteEvent)="deleteControl($event)"
                        (aswModelChange)="onSelectionChange($event)" (duplicateControl)="duplicateControl($event)">
                      </asw-textfield>
                    </ng-container>
                    <ng-container *ngSwitchCase="'textarea'">
                      <asw-textarea [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                        (textAreaUpdateEvent)="updatedControl($event)" (textAreaDeleteEvent)="deleteControl($event)"
                        (aswModelChange)="onSelectionChange($event)" (duplicateControl)="duplicateControl($event)">
                      </asw-textarea>
                    </ng-container>
                    <ng-container *ngSwitchCase="'number'">
                      <asw-number [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                        [formControls]="formElement" (numberUpdateEvent)="updatedControl($event)"
                        (numberDeleteEvent)="deleteControl($event)" (aswModelChange)="onSelectionChange($event)"
                        (duplicateControl)="duplicateControl($event)">
                      </asw-number>
                    </ng-container>
                    <ng-container *ngSwitchCase="'calculation'">
                      <asw-calculation [control]="control" [controlIndex]="i" [formControls]="formElement"
                        [isPreviewTemplate]="true" (calculationUpdateEvent)="updatedControl($event)"
                        (calculationDeleteEvent)="deleteControl($event)" (aswModelChange)="onSelectionChange($event)"
                        (duplicateControl)="duplicateControl($event)">
                      </asw-calculation>
                    </ng-container>
                    <ng-container *ngSwitchCase="'select'">
                      <asw-select [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                        (selectUpdateEvent)="updatedControl($event)" (selectDeleteEvent)="deleteControl($event)"
                        (selectionChange)="onSelectionChange($event)" (duplicateControl)="duplicateControl($event)">
                      </asw-select>
                    </ng-container>
                    <ng-container *ngIf="!control?.additional_type && !control.isICDX">
                      <ng-container *ngSwitchCase="'multi-select'">
                        <asw-multi-select [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                          (multiSelectUpdateEvent)="updatedControl($event)"
                          (multiSelectDeleteEvent)="deleteControl($event)" (selectionChange)="onSelectionChange($event)"
                          (duplicateControl)="duplicateControl($event)">
                        </asw-multi-select>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'radio'">
                      <asw-radio-button [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                        (radioButtonUpdateEvent)="updatedControl($event)"
                        (radioButtonDeleteEvent)="deleteControl($event)" (selectionChange)="onSelectionChange($event)"
                        (duplicateControl)="duplicateControl($event)">
                      </asw-radio-button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'checkbox'">
                      <asw-checkbox [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                        (checkboxUpdateEvent)="updatedControl($event)" (checkboxDeleteEvent)="deleteControl($event)"
                        (selectionChange)="onSelectionChange($event)" (duplicateControl)="duplicateControl($event)">
                      </asw-checkbox>
                    </ng-container>
                    <ng-container *ngSwitchCase="'datepicker'">
                      <asw-datepicker [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                        (datepickerUpdateEvent)="updatedControl($event)" (datepickerDeleteEvent)="deleteControl($event)"
                        (dateChange)="onSelectionChange($event)" (duplicateControl)="duplicateControl($event)">
                      </asw-datepicker>
                    </ng-container>
                    <ng-container *ngIf="control.additional_type === 'icd-x'|| control.isICDX">
                      <ng-container *ngSwitchCase="'multi-select'">
                        <form-element-icdx [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                          (multiSelectDeleteEvent)="deleteControl($event)" (selectionChange)="onSelectionChange($event)"
                          (duplicateControl)="duplicateControl($event)">
                        </form-element-icdx>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="control.additional_type === 'item-package'">
                      <ng-container *ngSwitchCase="'multi-select'">
                        <form-element-item-package [control]="control" [controlIndex]="i" [isPreviewTemplate]="true"
                          (multiSelectDeleteEvent)="deleteControl($event)" (selectionChange)="onSelectionChange($event)"
                          (duplicateControl)="duplicateControl($event)">
                        </form-element-item-package>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="buttons">
      <button type="button" class="batal" (click)="batalForm()">Batal</button>
      <button type="button" class="lihat" (click)="previewForm()">Lihat</button>
      <button type="button" class="reset" (click)="resetForm()">Reset</button>
      <button [disabled]="form.specialist_ids?.length <= 0 || form.role_ids?.length <=0" type="button" class="save"
        (click)="publishForm()">
        Save
      </button>
    </div>
  </div>
</section>
