import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../patient.service';
import { DetailPatientModel } from 'src/app/core/models/patient.model';
import { PatientEHRService } from '../../../../../core/service/patient-ehr.service';
import { Filter } from 'src/app/core/models/filter.interface';

@Component({
  selector: 'app-personal-information-patient',
  templateUrl: './personal-information-patient.component.html',
  styleUrls: ['./personal-information-patient.component.scss']
})
export class PersonalInformationPatientComponent {
  idDetail = '';
  patient = DetailPatientModel;

  countFormEHR: number;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private patientEHRService: PatientEHRService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(res => {
      this.idDetail = res.id;
    });
    this.getDetailPatient();
  }

  toDirectPage(item, id) {
    switch (item) {
      case 'identitas':
        this.router.navigate([`detail-pasien/identitas-pasien/${id}`]);
        break;
      case 'ehr':
        this.router.navigate([`detail-pasien/riwayat-ehr/${id}`]);
        break;
      default:
        this.router.navigate([`detail-pasien/riwayat-pasien/${id}`]);
        break;
    }
  }

  getDetailPatient() {
    const id = this.activatedRoute.snapshot.paramMap.get("id");
    this.patientService.getDetailPatient(id).subscribe((response) => {
      this.patient = response.data;
      const slug = this.patient['slug'];
      const params: Filter = {
        page: 1,
        limit: 500,
        slug: slug,
      };
      this.patientEHRService.listPatientEHR(params).subscribe(response => {
        this.countFormEHR = response.data?.total_data;
      });
    });
  }

}
