import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-patient-ehr',
  templateUrl: './modal-patient-ehr.component.html',
  styleUrls: ['./modal-patient-ehr.component.scss']
})
export class ModalPatientEHRComponent {
  keys: string[] = [];

  formData: any = null;

  constructor(
    public dialogRef: MatDialogRef<ModalPatientEHRComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.formData = data.data;
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
