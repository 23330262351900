import { v4 as uuidv4 } from "uuid";

export const FormModel = {
  name: '',
  description: '',
  branch_ids: [],
  specialist_ids: [],
  role_ids: [],
  status: 'active',
  elements: [],
  is_required: false,
  is_ttv: false,
};

export interface Form {
  id: string;
  name: string;
  slug: string;
  description: string;
  branch_ids: string[];
  specialist_ids: string[];
  role_ids: string[];
  status: 'active' | 'inactive';
  is_required: boolean;
  is_ttv: boolean;
  elements?: FormElement[];
  created_at: Date;
  updated_at: Date;
}

export interface FormElement {
  id?: string;
  icon: string;
  displayName: string;
  controlType: string;
  additional_type?: string;
  subtype?: string;
  tooltip?: string;
  isICDX?: boolean;
  value?: any;
  label: string;
  style: string;
  column: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  maxlength?: number;
  minlength?: number;
  guid?: string;
  customClass?: string;
  operations?: FormOperation[];
  placeholder?: string;
  isWeekendsDisable?: any;
  options?: FormOption[];
}

export interface FormOperation {
  id: string;
  label: string;
  value: any;
  operationValue: string;
  control?: FormElement;
}

export interface FormOption {
  key: string;
  value: string;
  isChecked: any;
}

export const mappingFormElements = (elements: FormElement[]): FormElement[] => {
  const result: FormElement[] = [];
  elements?.forEach((element, index) => {
    result.push(mappingFormElement(element, index));
  })
  return result;
}

const mappingFormElement = (element: FormElement, index: number): FormElement => {
  if (!element) return;
  const notValid: string[] = [null, undefined, ''];
  return {
    id: uuidv4(),
    icon: element.icon,
    displayName: element.displayName,
    controlType: element.controlType,
    additional_type: element.additional_type,
    subtype: element.subtype,
    tooltip: element.tooltip,
    value: element.value,
    label: element.label,
    style: element.style,
    column: element.column,
    isRequired: Boolean(element.isRequired),
    isDisabled: Boolean(element.isDisabled),
    maxlength: Number(element.maxlength),
    minlength: Number(element.minlength) || 0,
    guid: element.guid,
    isICDX: element.isICDX,
    customClass: element.customClass,
    operations: element.operations ? mappingFormOperations(element.operations) : null,
    placeholder: element.placeholder,
    isWeekendsDisable: notValid.includes(element.isWeekendsDisable) ? element.isWeekendsDisable : Boolean(element.isWeekendsDisable),
    options: element.options && (!element.additional_type || !element.isICDX) ? mappingFormOptions(element.options) : null,
  };
}

const mappingFormOperations = (operations: FormOperation[]): FormOperation[] => {
  if (!operations || operations.length === 0) return [];
  const result: FormOperation[] = [];

  operations?.forEach((operation, index) => {
    const mappedOperation: FormOperation = {
      id: operation.id,
      label: operation.label,
      value: operation.value,
      operationValue: operation.operationValue,
      control: mappingFormElement(operation.control, index),
    };
    result.push(mappedOperation);
  })
  return result;
};

const mappingFormOptions = (options: FormOption[]): FormOption[] => {
  if (!options || options.length === 0) return [];
  const notValid: string[] = [null, undefined, ''];
  return options.map(option => ({
    key: option.key,
    value: option.value,
    isChecked: notValid.includes(option.isChecked) ? option.isChecked : Boolean(option.isChecked)
  }));
}