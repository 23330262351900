import { Injectable } from "@angular/core";
import { HttpService } from "src/app/core/service/http.service";
@Injectable()
export class DoctorScheduleService {

  constructor(private httpService: HttpService) { }
  getAllDoctorSchedules(params: any = null) {
    return this.httpService.Get(`/v1/doctor-schedule/list`, params);
  }

  getDoctorSchedules(id) {
    return this.httpService.Get(`/v1/doctor-schedule/detail/`, id);
  }

  createDoctorSchedule(params: any) {
    return this.httpService.Post(`/v1/doctor-schedule/create`, params);
  }

  updateDoctorSchedule(params: any) {
    return this.httpService.Put(`/v1/doctor-schedule/update/${params.id}`, params);
  }

  deleteDoctorSchedule(id) {
    return this.httpService.Delete('/v1/doctor-schedule/delete', { id });
  }
}
