import { Injectable } from "@angular/core";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { HttpService } from "src/app/core/service/http.service";

@Injectable()
export class TransactionService extends UnsubscribeOnDestroyAdapter {
  constructor(
    private httpService: HttpService
  ) { super(); }

  createTransaction(data: any) {
    return this.httpService.Post(`/v1/transaction/create`, data);
  }
  getDetailTransaction(data: any) {
    return this.httpService.Post(`/v1/transaction/detail`, data);
  }

}
