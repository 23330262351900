import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Filter } from '../models/filter.interface';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor(
    private httpService: HttpService
  ) { }

  getProvince() {
    const params: Filter = {
      page: 1,
      limit: 1000,
      sort: 'code',
      dir: 'asc'
    };
    return this.httpService.Get(`/v1/province/list`, params);
  }

  getDistrict(code: any) {
    const params: Filter = {
      page: 1,
      limit: 100,
      parent_code: code,
      sort: 'code',
      dir: 'asc'
    };
    return this.httpService.Get(`/v1/district/list`, params);
  }

  getSubDistrict(code: any) {
    const params: Filter = {
      page: 1,
      limit: 100,
      parent_code: code,
      sort: 'code',
      dir: 'asc'
    };
    return this.httpService.Get(`/v1/sub-district/list`, params);
  }
}
