import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormElement,
  FormModel,
  mappingFormElements,
} from "src/app/core/models/form.model";
@Component({
  selector: "app-form-generator",
  templateUrl: "./form-generator.component.html",
  styleUrls: ["./form-generator.component.scss"],
})
export class FormGeneratorComponent implements OnInit {
  form = FormModel;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  clickBack() {
    this.router.navigate(["/form/list-form"]);
  }
  previewForm(elements: FormElement[]) {
    this.form.elements = mappingFormElements(elements);
  }
}
