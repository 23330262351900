import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/config/config.service";
import { AuthService } from "src/app/core/service/auth.service";
import { LanguageService } from "src/app/core/service/language.service";
import { getFileUrl } from "src/app/shared/helper";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  public config: any = {};
  userImg: string;
  homePage: string;
  isNavbarCollapsed = true;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  name: string;
  role: string;
  branchName: string;
  routeDetailPatient = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
  ) {
    super();
  }

  async ngOnInit() {
    const urlDetail = this.router.routerState.snapshot.url;
    const allowedURL: string[] = [
      "detail-pasien/detail-pasient",
      "detail-pasien/identitas-pasien",
      "detail-pasien/riwayat-pasien",
    ];
    if (allowedURL.includes(urlDetail)) {
      this.router.navigate(["detail-pasien/identitas-pasien"]);
      this.routeDetailPatient = true;
    } else {
      this.routeDetailPatient = false;
    }
    this.config = this.configService.configData;

    this.getDataSignMe();
  }

  async getDataSignMe() {
    const data: any = await this.authService.getMe();
    this.branchName = data.position_branch ? data.position_branch.name : "";
    this.name = data.name || "";
    this.role = data.role;
    if (data.image_id) {
      this.userImg = data.image_id;
    }
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains("side-closed");
    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["/auth/login"]);
      }
    });
  }
  settings() {
    this.router.navigate(["/setting"]);
  }
  getImage(id: string): string {
    if (!id) return null;
    return getFileUrl(id, 'private');
  }
}
