import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PatientHistoryComponent } from "./patient-history/patient-history.component";
import { PatientIdentityComponent } from "./patient-identity/patient-identity.component";
import { PatientFormComponent } from "./patient-form/patient-form.component";
import { PatientEHRComponent } from "./patient-ehr/patient-ehr.component";

const routes: Routes = [
  {
    path: "riwayat-pasien/:id",
    component: PatientHistoryComponent,
  },
  {
    path: "identitas-pasien/:id",
    component: PatientIdentityComponent,
  },
  {
    path: "form/:id/:appointment-id",
    component: PatientFormComponent,
  },
  {
    path: "riwayat-ehr/:id",
    component: PatientEHRComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DetailPatientRoutingModule {}
