import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: [],
})
export class MainLayoutComponent implements OnInit {
  constructor() { }
  ngOnInit(): void { }
}
