import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Appointment } from "./appointment.model";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { HttpService } from "src/app/core/service/http.service";
@Injectable()
export class AppointmentService extends UnsubscribeOnDestroyAdapter {
  dataChange: BehaviorSubject<Appointment[]> = new BehaviorSubject<
    Appointment[]
  >([]);
  dialogData: any;
  constructor(
    private httpService: HttpService
  ) {
    super();
  }
  get data(): Appointment[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  getAllAppointments(params: any = null) {
    return this.httpService.Get('/v1/appointment/list', params);
  }

  getDetailAppointment(params: any = null) {
    return this.httpService.Get(`/v1/appointment/detail/${params}`);
  }

  getQueue(params: any = null) {
    return this.httpService.Get('/v1/appointment/queue', params);
  }

  getSpecialist(params: any = null) {
    return this.httpService.Get('/v1/specialist/list', params);
  }

  updateStatus(id, params: any = null) {
    return this.httpService.Put('/v1/appointment/update/status/' + id, params);
  }

  saveAppointment(data: any) {
    return this.httpService.Post('/v1/appointment/create', data);
  }

  updateAppointment(data: any, id: string) {
    return this.httpService.Put(`/v1/appointment/update/${id}`, data);
  }

  addAppointment(appointment: Appointment): void {
    this.dialogData = appointment;
  }

  deleteAppointment(id: any) {
    return this.httpService.Delete('/v1/appointment/delete', { id: id });
  }

  updateAppointmentForm(data: any, id: string) {
    return this.httpService.Put(`/v1/appointment/update/form/${id}`, data);
  }
}
