import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DetailPatientRoutingModule } from './detail-patient-routing.module';
import { PatientHistoryComponent } from './patient-history/patient-history.component';
import { PatientIdentityComponent } from './patient-identity/patient-identity.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PatientService } from '../patient.service';
import { MatSelectModule } from '@angular/material/select';
import { PersonalInformationPatientComponent } from './personal-information-patient/personal-information-patient.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RoleService } from 'src/app/core/service/role.service';
import { PatientFormComponent } from './patient-form/patient-form.component';
import { FormGeneratorModule } from 'src/app/admin/form-generator/form-generator.module';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { PatientEHRComponent } from './patient-ehr/patient-ehr.component';
import { PatientEHRService } from 'src/app/core/service/patient-ehr.service';
import { ModalPatientEHRComponent } from './patient-ehr/modal/modal-patient-ehr.component';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@NgModule({
  declarations: [
    PatientHistoryComponent,
    PersonalInformationPatientComponent,
    PatientIdentityComponent,
    PatientFormComponent,
    PatientEHRComponent,
    ModalPatientEHRComponent
  ],
  imports: [
    CommonModule,
    DetailPatientRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSortModule,
    MatToolbarModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTableExporterModule,
    MatProgressSpinnerModule,
    ComponentsModule,
    SharedModule,
    NgxMatSelectSearchModule,
    NgxPaginationModule,
    FormGeneratorModule,
    MatNativeDateModule,
    MomentDateModule,
  ],
  exports: [
    PersonalInformationPatientComponent
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    PatientService,
    RoleService,
    PatientEHRService
  ]
})
export class DetailPatientModule { }
