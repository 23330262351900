import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class DirectionService {
  private data = new BehaviorSubject('');
  currentData = this.data.asObservable();
  private dataFormInput = new Subject()
  dataInput = this.dataFormInput.asObservable()

  updateDirection(item: any) {
    this.data.next(item);
  }

  shareGetDataFormBuilder(body: any) {
    this.dataFormInput.next(body)
  }

}
