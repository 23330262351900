<section class="content-setting">
  <div class="content-block">
    <div class="btn-back" (click)="clickBack()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </div>
    <div class="card">
      <div class="header">
        <h2>
          <strong>Security</strong> Settings</h2>
      </div>
      <div class="body">
        <div class="example-container">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>New Password</mat-label>
            <input type="password" matInput name="password" [(ngModel)]="password" [pattern]="passwordPattern">
            <mat-error *ngIf="!password.match(passwordPattern)">
              Minimal 6 karakter yang berisikan huruf kapital, dan huruf kecil.
            </mat-error>
          </mat-form-field>
        </div>
        <button mat-raised-button color="primary" (click)="updatePassword()">Save</button>
      </div>
    </div>
    <div class="card">
      <div class="header">
        <h2>
          <strong>Informasi Akun</strong></h2>
      </div>
      <div class="body">
        <div class="row clearfix">
          <div class="col-lg-12 col-md-12">
            <div class="w-100">
              <mat-form-field class="example-full-width mb-3" appearance="outline">
                <mat-label>Nama</mat-label>
                <input readonly matInput [(ngModel)]="data.name">
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="w-100">
              <mat-form-field class="example-full-width mb-3" appearance="outline">
                <mat-label>Email</mat-label>
                <input type="email" readonly matInput [(ngModel)]="data.email">
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="w-100">
              <mat-form-field class="example-full-width mb-3" appearance="outline">
                <mat-label>Tanggal Lahir</mat-label>
                <input readonly [max]="todayDate" matInput name="date_of_birth" [(ngModel)]="data.date_of_birth">
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="w-100">
              <mat-form-field class="example-full-width mb-3" appearance="outline">
                <mat-label>Nomor HP</mat-label>
                <input readonly type="number" matInput [(ngModel)]="data.phone_number">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
