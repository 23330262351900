import { DatePipe, formatDate } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentService } from "src/app/admin/appointment/viewappointment/appointment.service";
import { FormService } from "src/app/core/service/form.service";
import {
  Appointment,
  AppointmentForm,
} from "src/app/core/models/appointment.model";
import { MePort } from "src/app/core/models/auth.interface";
import { AuthService } from "../../../../../core/service/auth.service";
import { RoleService } from "src/app/core/service/role.service";
import { Filter } from "src/app/core/models/filter.interface";
import { lastValueFrom } from "rxjs";
import { HttpService } from "src/app/core/service/http.service";
import { Role } from "src/app/core/models/role";
import { ControlType } from "src/app/admin/form-generator/form-builder/default-controls";
import * as printJS from "print-js";
import { Form } from "src/app/core/models/form.model";
import * as moment from "moment";
import { PatientService } from "../../patient.service";
import { Patient } from "../../patient.model";
@Component({
  selector: "app-patient-form",
  templateUrl: "./patient-form.component.html",
  styleUrls: ["./patient-form.component.scss"],
})
export class PatientFormComponent implements OnInit {
  selectedIndex: number = 0;
  appointment: Appointment;
  appointmentForm: AppointmentForm[] = [];
  currentDate = new Date();
  year = this.currentDate.getFullYear();
  month = this.currentDate.getMonth();
  patient: Patient;
  pemeriksa: string;
  formTTVList: Form[] = [];

  firstDate = new Date(this.year, this.month, 1);
  lastDate = new Date(this.year, this.month + 1, 0);
  date = formatDate(this.currentDate, "yyyy-MM-dd", "en");
  appointmentDate: string = "";

  isAlreadySubmit: boolean = true;

  branchId = "";
  me: MePort;
  roles: any;

  constructor(
    private router: Router,
    private formService: FormService,
    private appointmentService: AppointmentService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private roleService: RoleService,
    private httpService: HttpService,
    private datePipe: DatePipe,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.getMe();
  }

  getMe = async () => {
    this.me = await this.authService.getMe();
    this.branchId = this.me.position_branch?.id;
    this.getRoles();
    this.loadData();
    this.getDetailPatient();
  };

  clickBack() {
    this.router.navigate([
      `detail-pasien/riwayat-pasien/${this.appointment.patient["id"]}`,
    ]);
  }

  async loadData() {
    const roles = await this.getRoles();
    const roleIds: string[] = roles?.map((role: any) => role.id);
    const appointmentId =
      this.activatedRoute.snapshot.paramMap.get("appointment-id");
    this.appointmentService
      .getDetailAppointment(appointmentId)
      .subscribe(async (response: any) => {
        this.appointment = response.data;
        this.appointmentDate = this.appointment.date;
        const forms = await this.getForms(
          roleIds,
          this.appointment.doctor_schedule?.specialist?.id
        );
        if (!this.appointment.forms) {
          this.isAlreadySubmit = false;
          this.appointment.forms = forms.filter((form) => {
            if (form.form.specialist_ids && form.form.specialist_ids.length > 0)
              return form.form.specialist_ids.includes(
                this.appointment.doctor_schedule?.specialist?.id
              );
            return true;
          });
        }
        forms?.forEach((form: AppointmentForm) => {
          if (
            form.form?.specialist_ids.includes(
              this.appointment.doctor_schedule?.specialist?.id
            )
          ) {
            if (
              !this.appointment.forms.some(
                (existingForm: AppointmentForm) =>
                  existingForm.form.id === form.form.id
              )
            )
              this.appointment.forms.push(form);
            if (
              form.form.is_ttv &&
              roleIds.some((item) => form.form.role_ids.includes(item))
            ) {
              const formUser = this.appointment.forms.find(
                (item) => item.form.id === form.form.id
              );
              if (formUser) {
                const formTTV = JSON.parse(JSON.stringify(formUser.form)); // Deep copy of formData.form
                formTTV.elements.forEach((element) => {
                  element.isDisabled = true; // Set isDisabled to true for each element in formTTV.elements
                });
                this.formTTVList.push(formTTV);
              }
            }
          }
        });
        this.appointment.forms?.forEach((formData) => {
          if (roleIds.some((item) => formData.form.role_ids.includes(item))) {
            this.appointmentForm.push(formData);
          }
        });

        const index = this.activatedRoute.snapshot.queryParamMap.get("index");
        if (index) this.selectedIndex = Number(index);
      });
  }

  async getForms(
    roleIds: string[],
    specialistId?: string
  ): Promise<AppointmentForm[]> {
    const dataList: AppointmentForm[] = [];
    const param: Filter = {
      page: 1,
      limit: 500,
      role_list: roleIds.join(","),
      branch_id: this.branchId,
      specialist_id: specialistId,
    };
    const resp = await lastValueFrom(this.formService.listForm(param));
    const items = resp.data.list;
    for (const item of items) {
      dataList.push({
        is_filled: false,
        updated_at: null,
        updated_by_name: "",
        form: item,
      });
    }
    return dataList;
  }

  async getRoles(): Promise<any[]> {
    const role: string = this.me.role;
    const roles: string[] = [role];
    if (this.me.role === Role.ADMINCENTRAL) roles.push(Role.ADMIN);
    const params = {
      page: 1,
      limit: 100,
      role_list: roles.join(","),
    };
    return lastValueFrom(this.roleService.listRole(params)).then((result) => {
      return result.data.list;
    });
  }

  submitData(id: string) {
    const index = this.appointment.forms.findIndex(
      (formData) => formData.form.id === id
    );
    const formData: AppointmentForm = {
      form: this.appointment.forms[index].form,
      updated_at: new Date(),
      updated_by_name: this.me.name,
      is_filled: true,
    };
    const otherElement: string[] = [
      ControlType.ICD_X,
      ControlType.ITEM_PACKAGE,
    ];
    formData.form.elements.forEach((element) => {
      if ("value" in element) {
        if (!element.value && element.isRequired) formData.is_filled = false;
      }
      if (otherElement.includes(element?.additional_type) || element.isICDX)
        element.options = null;
    });
    this.appointment.forms[index] = formData;
    const data = {
      forms: this.appointment.forms,
    };
    this.appointmentService
      .updateAppointmentForm(data, this.appointment["id"])
      .subscribe((res) => {
        this.httpService.SuccessProcess("Success update data form");
        this.redirectStatusJanjiTemu();
      });
  }

  getDetailPatient() {
    const id = this.activatedRoute.snapshot.paramMap.get("id");
    this.patientService.getDetailPatient(id).subscribe((response) => {
      this.patient = response.data;
    });
  }

  printForm(form: Form) {
    const properties: string[] = [];
    const result = {};
    const index = this.appointment.forms.findIndex(
      (formData) => formData.form.id === form.id
    );
    this.pemeriksa = this.appointment.forms[index].updated_by_name;

    form.elements.forEach((element) => {
      const optionsValue = element.options?.filter(
        (option) => option.isChecked
      );
      if (
        (element.value !== undefined && element.value !== null) ||
        (optionsValue && optionsValue.length > 0)
      ) {
        const property = element.displayName || element.label;
        properties.push(property);
        let value = element.value;
        if (element.controlType === ControlType.DATEPICKER)
          value = element.value
            ? moment(element.value).format("DD-MM-yyyy")
            : "";
        else if (optionsValue?.length > 0)
          value = optionsValue
            .map((result) => (result.value ? result.value : "-"))
            .join(",");
        result[property] = value;
      }
    });

    const dateOfBirth = this.patient?.date_of_birth
      ? this.datePipe.transform(this.patient.date_of_birth, "dd-MM-yyyy")
      : "";

    const printableContent = `
    <div class="printable-json">
      <div class="logo">
        <img src="assets/images/banner/print-logo.png" alt="Logo" style="width: 19.2px; height: 21.5px;">
        <div class="logo-text">KMNC</div>
      </div>
      <div>
        <h2 style="font-size: 10px; text-align:center; ">${form.name}</h2>
      </div>
      <hr style="border: none; height: 2px; background-color: #ddd;">
      <div class="info">
        <div class="info-title">Informasi Pasien</div>
        <div class="infos">
          <div class="info-item grey">
            <div class="index">Nama</div>
            <div class="value">${this.patient.name}</div>
          </div>
          <div class="info-item grey">
            <div class="index">No. RM</div>
            <div class="value">${this.patient.medical_record}</div>
          </div>
          <div class="info-item">
            <div class="index">Nomor Telepon</div>
            <div class="value">${this.patient.phone_number}</div>
          </div>
          <div class="info-item">
            <div class="index">Tanggal Lahir</div>
            <div class="value">${dateOfBirth}</div>
          </div>
        </div>
      </div>
      <div class="${Object.keys(result).length > 64 ? "tables" : "table"}">
        ${Object.entries(result)
        .map(
          ([key, value], index) => `
            <div class="info-item ${Object.keys(result).length > 64
              ? "white"
              : index % 2
                ? "white"
                : "grey"
            }" >
              <div class= "index" >${key}</div>
              <div class="value">${value}</div>
            </div>

          `
        )
        .join("")}
      </div>
      <div class="footer">
        <div class="sign">
          <div class="value">Pemeriksa</div>
          <div class="index">${this.pemeriksa}</div>
        </div>
        <div class="disclaimer">
          USG bersifat prediksi untuk menilai kondisi fisik janin dan tidak dapat
          memastikan ada tidaknya kelainan genetik secara menyeluruh. Ketepatan
          hasil USG sangat bervariasi ditentukan oleh kondisi ibu, janin, dan
          operator.
        </div>
        <div class="end">
          <div>www.kmnc.co.id</div>
          <div>care.affordable.comfortable</div>
        </div>
      </div>
    </div>
  `;

    printJS({
      printable: printableContent,
      type: "raw-html",
      properties: properties,
      css: "https://printjs-4de6.kxcdn.com/print.min.css",
      header: "test",
      documentTitle: "-",
      style: `
      .printable-json {
        padding: 0px;
      }
      .info-title {
        color: var(--pink-darker, #9d4b50);
        font-family: 'Inter', sans-serif;
        font-size: 7px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 10.5px */
      }
      .logo {
        display: flex;
        gap: 4.5px;
        align-items: center;
      }
      .logo-text {
        color: #d35662;
        font-family: 'Poppins', sans-serif;
        font-size: 14.671px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 22.007px */
        -webkit-print-color-adjust: exact;
        margin-bottom: -7px;
      }
      .info {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .infos {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 17px;
        row-gap: 2px;
      }
      .tables {
        width: 100%;
        margin-top: 24px;
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 17px;
        row-gap: 2px;
      }
      .info-item {
        display: flex;
        justify-content: space-between;
      }
      .index, .disclaimer {
        color: #000;
        /* Small Bold */
        font-family: 'Inter', sans-serif;
        font-size: 6px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 9px */
        width: 100%;

      }
      .value {
        color: #000;
        /* Small */
        font-family: 'Inter', sans-serif;
        font-size: 6px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 9px */
        width: 100%;
      }
      .grey {
        background: var(--neutral-black-100, #F7F7F7);
        -webkit-print-color-adjust: exact;
      }
      .white {
        background: var(--neutral-white, #fff);
        -webkit-print-color-adjust: exact;
      }
      .table {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
    .footer {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100%;
      bottom: 0px;
      height: 140px;
    }
    .sign {
      display: flex;
      flex-direction: column;
      gap: 32px;
      text-align: center;
      position: absolute;
      right: 93px;
    }
    .disclaimer {
      color: #333;
      margin-top: 84px;
      text-align: center;
    }
    .end {
      position: absolute;
      bottom: 0px;
      right: 0px;
      left: 0px;
      display: flex;
      justify-content: space-between;
      padding: 3px 20px;
      background-color: #9d4b50;
      -webkit-print-color-adjust: exact;
      color: #fff;
      /* Overline */
      font-family: 'Inter', sans-serif;
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    `,
    });
  }

  redirectAppointment() {
    this.router.navigate([`/janji-temu/lihat-janji`]);
  }
  redirectStatusJanjiTemu() {
    this.router.navigate([`/janji-temu/status-janji`]);
  }
}
