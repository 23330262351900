import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BranchPort } from 'src/app/core/models/branch.interface';
import { AuthService } from 'src/app/core/service/auth.service';
import { getFileUrl } from 'src/app/shared/helper';

@Component({
  selector: 'app-dashboard-cabang',
  templateUrl: './dashboard-cabang.component.html',
  styleUrls: ['./dashboard-cabang.component.scss']
})
export class DashboardCabangComponent implements OnInit {
  protected branchs: BranchPort[];

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  async ngOnInit(): Promise<void> {
    const user = await this.authService.getMe();
    this.branchs = user.branchs;
  }

  clickToDirect(id) {
    this.authService.updatePositionBranch({ branch_id: id }).subscribe(
      resp => {
        if (resp.status === 200) {
          localStorage.removeItem('me');
          this.authService.getMe();
          setTimeout(() => {
            this.router.navigate([`/janji-temu/status-janji`]);
          }, 1000);
        }
      }
    );
  }

  getImage(id: string): string {
    if (!id) { return; }
    return getFileUrl(id, 'private');
  }
}


