<div class="cont card mt-5 pt-5 mb-5">
  <div class="btn-back mb-5" (click)="clickBack()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </div>
  <div class="row">
    <mat-form-field class="col-md-12" appearance="outline">
      <mat-label>Nama</mat-label>
      <input matInput [(ngModel)]="form.name" name="name" disabled />
    </mat-form-field>
    <mat-form-field class="col-md-12" appearance="outline">
      <mat-label>Deskripsi</mat-label>
      <textarea matInput [(ngModel)]="form.description" disabled></textarea>
    </mat-form-field>
    <mat-form-field class="col-md-12" appearance="outline">
      <mat-label>Akses Role</mat-label>
      <mat-select [(ngModel)]="form.role_ids" multiple disabled>
        <mat-option *ngFor="let item of roles" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-lg-12 col-md-12" appearance="outline">
      <mat-label>Akses Poli</mat-label>
      <mat-select [(ngModel)]="form.specialist_ids" multiple disabled>
        <mat-option
          *ngFor="let specialist of specialists"
          [value]="specialist.id"
        >
          {{ specialist.name }} ({{ specialist.branch.name }})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="col-md-12">
      <mat-label>Form Builder</mat-label>
      <preview-template class="mt-5 mb-5" [formContainer]="form?.elements">
      </preview-template>
    </div>
  </div>
</div>
