import { v4 as uuidv4 } from "uuid";

export enum ControlType {
  HEADER = "header",
  TEXT_FIELD = "textfield",
  TEXT_AREA = "textarea",
  NUMBER = "number",
  CALCULATION = "calculation",
  SELECT = "select",
  MULTI_SELECT = "multi-select",
  RADIO = "radio",
  CHECKBOX = "checkbox",
  DATEPICKER = "datepicker",
  ICD_X = "icd-x",
  ITEM_PACKAGE = "item-package",
}
export const TEXT_ELEMENT_CONTROLS = [
  {
    id: uuidv4(),
    icon: "title",
    displayName: "Judul",
    controlType: ControlType.HEADER,
    subtype: "h1",
    style: "text-left",
    customClass: 'mb-3',
    label: "Judul",
    column: "col-md-12",
  },
  {
    id: uuidv4(),
    icon: "text_fields",
    displayName: "Teks",
    controlType: ControlType.TEXT_FIELD,
    tooltip: "Masukan Text.",
    value: "",
    label: "Teks",
    style: "outline",
    column: "col-md-6",
    isRequired: false,
    isDisabled: false,
    maxlength: 50,
    minlength: 0,
  },
  {
    id: uuidv4(),
    icon: "short_text",
    displayName: "Paragraf",
    controlType: ControlType.TEXT_AREA,
    tooltip: "Masukan paragraf.",
    value: "",
    label: "Paragraf",
    style: "outline",
    column: "col-md-6",
    isRequired: false,
    isDisabled: false,
    maxlength: 50,
    minlength: 0,
  },
  {
    id: uuidv4(),
    icon: "numbers",
    displayName: "Angka",
    controlType: ControlType.NUMBER,
    tooltip: "Masukkan nilai dalam bentuk angka.",
    value: "",
    label: "Angka",
    style: "outline",
    column: "col-md-6",
    isRequired: false,
    isDisabled: false,
    maxlength: 50,
    minlength: 0,
  },
  {
    id: uuidv4(),
    icon: "calculate",
    displayName: "Kalkulasi",
    controlType: ControlType.CALCULATION,
    tooltip:
      "Pilih field number yang sudah tersedia untuk melakukan perhitungan.",
    value: "",
    label: "  Kalkulasi",
    placeholder: "Field 1 + Field 2 = ",
    style: "outline",
    column: "col-md-6",
    operations: [],
  },
];

export const MULTI_ELEMENT_CONTROLS = [
  {
    id: uuidv4(),
    icon: "fact_check",
    displayName: "Dropdown",
    controlType: ControlType.SELECT,
    tooltip: "Pilih satu opsi dari beberapa pilihan yang tersedia",
    label: "Dropdown",
    style: "outline",
    column: "col-md-6",
    value: "",
    isRequired: false,
    isDisabled: false,
    options: [
      { key: "pilihan-1", value: "Pilihan 1", isChecked: false },
      { key: "pilihan-2", value: "Pilihan 2", isChecked: false },
      { key: "pilihan-3", value: "Pilihan 3", isChecked: false },
    ],
  },
  {
    id: uuidv4(),
    icon: "checklist",
    displayName: "Multiple Dropdown",
    controlType: ControlType.MULTI_SELECT,
    tooltip: "Centang kotak pada opsi yang ingin dipilih.",
    label: "Multiple Dropdown",
    column: "col-md-6",
    value: "",
    style: "outline",
    isRequired: false,
    isDisabled: false,
    options: [
      { key: "pilihan-1", value: "Pilihan 1", isChecked: false },
      { key: "pilihan-2", value: "Pilihan 2", isChecked: false },
      { key: "pilihan-3", value: "Pilihan 3", isChecked: false },
    ],
  },
  {
    id: uuidv4(),
    icon: "radio_button_checked",
    displayName: "Pilihan Ganda",
    controlType: ControlType.RADIO,
    tooltip: "Pilih salah satu opsi yang tersedia.",
    label: "Pilihan Ganda",
    value: "pilihan-1",
    column: "col-md-6",
    isRequired: false,
    isDisabled: false,
    options: [
      { key: "pilihan-1", value: "Pilihan 1", isChecked: false },
      { key: "pilihan-2", value: "Pilihan 2", isChecked: false },
      { key: "pilihan-3", value: "Pilihan 3", isChecked: false },
    ],
  },
  {
    id: uuidv4(),
    icon: "check_box",
    displayName: "Kotak Centang",
    controlType: ControlType.CHECKBOX,
    tooltip: "Centang kotak untuk memilih opsi yang tersedia.",
    label: "Kotak Centang",
    column: "col-md-6",
    isRequired: false,
    isDisabled: false,
    options: [
      { key: "pilihan-1", value: "Pilihan 1", isChecked: false },
      { key: "pilihan-2", value: "Pilihan 2", isChecked: false },
      { key: "pilihan-3", value: "Pilihan 3", isChecked: false },
    ],
  },
];

export const DATE_ELEMENT_CONTROLS = [
  {
    id: uuidv4(),
    icon: "event",
    displayName: "Tanggal",
    controlType: ControlType.DATEPICKER,
    value: "",
    tooltip: "Silakan pilih tanggal",
    label: "Tanggal",
    style: "outline",
    column: "col-md-6",
    isRequired: false,
    isWeekendsDisable: false,
    isDisabled: false,
  },
];

export const OTHER_ELEMENT_CONTROLS = [
  {
    id: uuidv4(),
    icon: "check_box",
    displayName: "ICD X",
    controlType: ControlType.MULTI_SELECT,
    isICDX: true,
    additional_type: ControlType.ICD_X,
    tooltip: "Centang kotak untuk memilih opsi yang tersedia.",
    label: "ICD X",
    column: "col-md-6",
    isRequired: false,
    isDisabled: false,
    options: [],
  },
  {
    id: uuidv4(),
    icon: "check_box",
    displayName: "Item Package",
    controlType: ControlType.MULTI_SELECT,
    additional_type: ControlType.ITEM_PACKAGE,
    tooltip: "Centang kotak untuk memilih opsi yang tersedia.",
    label: "Item Package",
    column: "col-md-6",
    isRequired: false,
    isDisabled: false,
    options: [],
  },
];
