import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  MULTI_ELEMENT_CONTROLS,
  DATE_ELEMENT_CONTROLS,
  OTHER_ELEMENT_CONTROLS,
  TEXT_ELEMENT_CONTROLS,
} from "./default-controls";
import { FormPreviewComponent } from "../form-preview/form-preview.component";
import { FormModel, mappingFormElements } from "src/app/core/models/form.model";
import { HttpService } from "src/app/core/service/http.service";
import { ErrorService } from "src/app/core/service/error.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormService } from "src/app/core/service/form.service";
import { BranchService } from "src/app/core/service/branch.service";
import { DefaultResponse } from "src/app/core/models/response.interface";
import { ResponseData } from "../../../core/models/response.interface";
import { RoleService } from "src/app/core/service/role.service";
import { Role } from "src/app/core/models/role";
import { ICDX } from "src/app/core/models/icd-x.interface";
import { ICDXService } from "src/app/core/service/icd-x.service";
import { Overlay } from "@angular/cdk/overlay";
import { PoliService } from "src/app/core/service/poli.service";
import { BranchPort } from "src/app/core/models/branch.interface";

@Component({
  selector: "form-builder",
  templateUrl: "./form-builder.html",
  styleUrls: ["./form-builder.scss"],
})
export class FormBuilder implements OnInit, OnChanges {
  @Input() isShowPublishButton = true;
  @Input() isShowPreviewButton = true;
  @Input() formData: any;

  @Output() buttonClick = new EventEmitter<any[]>();
  @Output() previewClick = new EventEmitter<any[]>();

  textElementControls: any[] = TEXT_ELEMENT_CONTROLS;
  dateElementControls: any[] = DATE_ELEMENT_CONTROLS;
  multiElementControls: any[] = MULTI_ELEMENT_CONTROLS;
  otherElementControls: any[] = OTHER_ELEMENT_CONTROLS;

  icdXItems: ICDX[];
  icdXOptions: any[];

  @Output() ModelChange = new EventEmitter<any>();

  form = FormModel;
  branchs: BranchPort[];
  roles: any;
  step = 0;
  formElement: any[] = [];
  action: string = "create";
  specialists: any = [];
  searchSpecialists: any = [];

  constructor(
    public dialog: MatDialog,
    private httpService: HttpService,
    private errorService: ErrorService,
    private router: Router,
    private formService: FormService,
    private branchService: BranchService,
    private roleService: RoleService,
    private icdXService: ICDXService,
    private activatedRoute: ActivatedRoute,
    private overlay: Overlay,
    private specialistService: PoliService
  ) {
    this.resetForm();
  }

  ngOnInit(): void {
    this.resetForm();
    this.getRoles();
    this.getSpecialists();
    this.listICDX();
    const id = this.activatedRoute.snapshot.paramMap.get("id");
    if (id) this.action = "edit";
  }

  setStep(index: number): void {
    this.step = index;
  }

  ngOnChanges(): void {
    if (this.formData && this.formData.elements?.length > 0) {
      this.form = this.formData;
      this.formElement = this.formData.elements;
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        JSON.parse(JSON.stringify(event.previousContainer.data)),
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  updatedControl(data: any): void {
    this.formElement.splice(data.index, 1, data.control);
  }

  deleteControl(index: any): void {
    this.formElement.splice(index, 1);
  }

  previewForm(): void {
    const dialogRef = this.dialog.open(FormPreviewComponent, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      disableClose: true,
      width: "744px",
      data: this.formElement,
    });
    dialogRef.afterClosed();
    this.previewClick.emit(this.formElement);
  }

  publishForm() {
    if (this.formElement?.length <= 0)
      return this.errorService.handleError("form element harus diisi!");
    this.form.elements = mappingFormElements(this.formElement);
    if (this.form.specialist_ids.length <= 0)
      return this.errorService.handleError("form poli akses harus diisi!");

    const branchIds: string[] = [];
    this.specialists.map((specialist) => {
      if (this.form.specialist_ids.includes(specialist.id)) {
        if (!branchIds.includes(specialist.branch.id))
          branchIds.push(specialist.branch.id);
      }
    });
    this.form.branch_ids = branchIds;
    if (this.action === "edit") {
      this.formService
        .updateForm(this.formData.id, this.form)
        .subscribe((response) => {
          this.resetForm();
          this.httpService.SuccessProcess("Berhasil Update Form");
          this.router.navigate(["/form/list-form"]);
        });
    } else {
      this.formService.createForm(this.form).subscribe((response: any) => {
        this.resetForm();
        this.httpService.SuccessProcess("Berhasil Menambahkan Form");
        this.router.navigate(["/form/list-form"]);
      });
    }
  }

  onSelectionChange(control: any): void {
    this.ModelChange.emit(control);
  }

  duplicateControl(control: any): void {
    const duplicatedControl = JSON.parse(JSON.stringify(control));
    this.formElement.push(duplicatedControl);
  }

  pushControl(control: any): void {
    const newControl = JSON.parse(JSON.stringify(control));
    this.formElement.push(newControl);
  }

  listICDX(keyword: string = "") {
    const params = {
      page: 1,
      limit: 1000000,
      q: keyword,
      sort: "diagnosis",
      dir: "asc",
    };
    this.icdXService.listICDX(params).subscribe((res: any) => {
      this.icdXItems = res.data.list;
      this.icdXOptions = this.icdXItems.map((icdX) => {
        return { value: icdX.diagnosis, key: icdX.code, isChecked: false };
      });
    });
  }

  resetForm() {
    this.form = {
      name: "",
      description: "",
      branch_ids: [],
      specialist_ids: [],
      role_ids: [],
      status: "active",
      elements: [],
      is_required: false,
      is_ttv: false,
    };
    this.formElement = [];
  }

  batalForm() {
    this.router.navigate(["/form/list-form"]);
  }
  getSpecialists() {
    const params = {
      page: 1,
      limit: 1000,
    };
    this.specialistService
      .listPoli(params)
      .subscribe((res: DefaultResponse<ResponseData>) => {
        if (res.data?.total_data > 0) {
          this.specialists = res.data.list;
          this.searchSpecialists = res.data.list;
          // if (this.form.specialist_ids?.length <= 0)
          //   this.form.specialist_ids = this.specialists.map(
          //     (specialist: any) => specialist.id
          //   );
        }
      });
  }

  getRoles() {
    const roles: string[] = [
      Role.ADMIN,
      Role.ADMINCENTRAL,
      Role.DOCTOR,
      Role.PATIENT,
    ];
    const params = {
      page: 1,
      limit: 100,
      role_list: roles.join(","),
    };
    this.roleService
      .listRole(params)
      .subscribe((res: DefaultResponse<ResponseData>) => {
        if (res.data.total_data > 0) {
          this.roles = res.data.list;
          // if (this.form.role_ids?.length <= 0)
          //   this.form.role_ids = this.roles.map((role: any) => role.id);
        }
      });
  }

  searchSpecialist(event: any) {
    const input = event.target.value;
    const filterValueSpecialist = input.toLowerCase();
    this.searchSpecialists = this.specialists.filter((option: any) =>
      option.name.toLowerCase().includes(filterValueSpecialist)
    );
  }
}
