import { Component, Inject, OnInit, EventEmitter, Output, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-form-preview',
  templateUrl: './form-preview.component.html',
  styleUrls: ['./form-preview.component.scss']
})
export class FormPreviewComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FormPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  @Output() previewClick = new EventEmitter<any[]>();

  ngOnInit(): void { }
}
