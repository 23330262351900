import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AddressResponse } from "src/app/core/models/address.model";
import { AddressService } from "src/app/core/service/address.service";
import { HttpService } from "src/app/core/service/http.service";
import { PatientService } from "../../patient.service";
import { DetailPatientModel } from "src/app/core/models/patient.model";

@Component({
  selector: 'app-patient-identity',
  templateUrl: './patient-identity.component.html',
  styleUrls: ['./patient-identity.component.scss']
})
export class PatientIdentityComponent {
  patient = DetailPatientModel;
  patienDataValue: any;
  golonganDarahArray = ["A", "B", "O", "AB"];
  pendidikanArray = ["SD", "SMP", "SMA/SMK", "D3", "S1", "S2", "S3"];
  provinces: AddressResponse[] = [];
  searchProvinces: AddressResponse[] = [];
  districts: AddressResponse[] = [];
  searchDistricts: AddressResponse[] = [];
  subDistricts: AddressResponse[] = [];
  searchSubDistricts: AddressResponse[] = [];
  searchProvincesFather: AddressResponse[] = [];
  searchDistrictsFather: AddressResponse[] = [];
  searchSubDistrictsFather: AddressResponse[] = [];
  searchProvincesMother: AddressResponse[] = [];
  searchDistrictsMother: AddressResponse[] = [];
  searchSubDistrictsMother: AddressResponse[] = [];
  provinceSelected: any;
  districtSelected: any;
  subDistrictSelected: any;
  provinceSelectedFather: any;
  districtSelectedFather: any;
  subDistrictSelectedFather: any;
  provinceSelectedMother: any;
  districtSelectedMother: any;
  subDistrictSelectedMother: any;
  todayDate = new Date();

  constructor(
    public patientService: PatientService,
    public addressService: AddressService,
    private activatedRoute: ActivatedRoute,
    public httpService: HttpService,
    private router: Router,
    private datepipe: DatePipe

  ) { }

  ngOnInit(): void {
    this.getDetailPatient();
    this.getProvince();
  }

  clickBack() {
    this.router.navigate(['patients/all-patients']);
  }

  getDetailPatient() {
    const id = this.activatedRoute.snapshot.paramMap.get("id");
    this.patientService.getDetailPatient(id).subscribe((response) => {
      this.patient = response.data;
      if (this.patient.additional === null) {
        this.defaultValueAdditional();
      }
      this.patienDataValue = response.data;
    });
  }

  getProvince() {
    this.addressService.getProvince().subscribe((response: any) => {
      this.provinces = response.data.list;
      this.searchProvinces = response.data.list;
      this.searchProvincesFather = response.data.list;
      this.searchProvincesMother = response.data.list;
      if (this.patienDataValue?.address?.province?.id) {
        this.provinceSelected = this.provinces.find(
          (x) => x.id === this.patienDataValue.address?.province?.id
        );
        if (this.provinceSelected) {
          this.patient.address.province_id = this.provinceSelected.id;
          this.getDistrict(this.provinceSelected.code, "patient");
        }
      }
      if (this.patienDataValue?.additional?.parent.father.address?.province?.id) {
        this.provinceSelectedFather = this.provinces.find(
          (x) =>
            x.id ===
            this.patienDataValue?.additional?.parent.father.address?.province?.id
        );
        if (this.provinceSelectedFather) {
          this.patient.additional.parent.father.address.province.id = this.provinceSelectedFather.id;
          this.getDistrict(this.provinceSelectedFather.code, "father");
        }
      }
      if (this.patienDataValue?.additional?.parent.mother.address?.province?.id) {
        this.provinceSelectedMother = this.provinces.find(
          (x) =>
            x.id ===
            this.patienDataValue?.additional?.parent.mother.address?.province?.id
        );
        if (this.provinceSelectedMother) {
          this.patient.additional.parent.mother.address.province.id = this.provinceSelectedMother.id;
          this.getDistrict(this.provinceSelectedMother.code, "mother");
        }
      }
    });
  }

  onChangeProvince(event: any, type: string = "patient") {
    if (type === "patient") {
      this.patient.address.province_id = event.value.id;
      this.getDistrict(event.value.code, "patient");
    }
    if (type === "father") {
      this.patient.additional.parent.father.address.province = {
        id: event.value.id
      }
      this.getDistrict(event.value.code, "father");
    }
    if (type === "mother") {
      this.patient.additional.parent.mother.address.province = {
        id: event.value.id
      }
      this.getDistrict(event.value.code, "mother");
    }
  }

  searchProvince(event: any, type: string) {
    const input = event.target.value;
    const filterValueProvince = input.toLowerCase();
    if (type === "patient") {
      this.searchProvinces = this.provinces.filter((option: any) =>
        option.name.toLowerCase().includes(filterValueProvince)
      );
    }
    if (type === "father") {
      this.searchProvincesFather = this.provinces.filter((option: any) =>
        option.name.toLowerCase().includes(filterValueProvince)
      );
    }
    if (type === "mother") {
      this.searchProvincesMother = this.provinces.filter((option: any) =>
        option.name.toLowerCase().includes(filterValueProvince)
      );
    }
  }

  getDistrict(code: number, type: string) {
    this.addressService.getDistrict(code).subscribe((response: any) => {
      this.districts = response.data.list;
      if (type === "patient") {
        this.searchDistricts = response.data.list;
        if (this.patienDataValue?.address?.district?.id) {
          this.districtSelected = this.districts.find(
            (x) => x.id === this.patienDataValue.address?.district?.id
          );
          if (this.districtSelected) {
            this.patient.address.district_id = this.districtSelected.id;
            this.getSubDistrict(this.districtSelected.code, "patient");
          }
        }
      }
      if (type === "father") {
        this.searchDistrictsFather = response.data.list;
        if (
          this.patienDataValue?.additional.parent.father.address?.district?.id
        ) {
          this.districtSelectedFather = this.districts.find(
            (x) =>
              x.id ===
              this.patienDataValue.additional.parent.father.address?.district?.id
          );
          if (this.districtSelectedFather) {
            this.patient.additional.parent.father.address.district.id = this.districtSelectedFather.id;
            this.getSubDistrict(this.districtSelectedFather.code, "father");
          }
        }
      }

      if (type === "mother") {
        this.searchDistrictsMother = response.data.list;
        if (
          this.patienDataValue?.additional.parent.mother.address?.district?.id
        ) {
          this.districtSelectedMother = this.districts.find(
            (x) =>
              x.id ===
              this.patienDataValue.additional.parent.mother.address?.district?.id
          );
          if (this.districtSelectedMother) {
            this.patient.additional.parent.mother.address.district.id = this.districtSelectedMother.id;
            this.getSubDistrict(this.districtSelectedMother.code, "mother");
          }
        }
      }
    });
  }

  searchDistrict(event: any, type: string) {
    const input = event.target.value;
    const filterValueDistrict = input.toLowerCase();
    if (type === "patient") {
      this.searchDistricts = this.districts.filter((option: any) =>
        option.name.toLowerCase().includes(filterValueDistrict)
      );
    }
    if (type === "father") {
      this.searchDistrictsFather = this.districts.filter((option: any) =>
        option.name.toLowerCase().includes(filterValueDistrict)
      );
    }
    if (type === "mother") {
      this.searchDistrictsMother = this.districts.filter((option: any) =>
        option.name.toLowerCase().includes(filterValueDistrict)
      );
    }
  }

  onChangeDistrict(event: any, type: string = "patient") {
    if (type === "patient") {
      this.patient.address.district_id = event.value.id;
      this.getSubDistrict(event.value.code, "patient");
    }
    if (type === "father") {
      this.patient.additional.parent.father.address.district = {
        id: event.value.id
      }
      this.getSubDistrict(event.value.code, "father");
    }
    if (type === "mother") {
      this.patient.additional.parent.mother.address.district = {
        id: event.value.id
      }
      this.getSubDistrict(event.value.code, "mother");
    }
  }

  getSubDistrict(code: number, type: string) {
    this.addressService.getSubDistrict(code).subscribe((response: any) => {
      this.subDistricts = response.data.list;
      if (type === "patient") {
        this.searchSubDistricts = response.data.list;
        if (this.patienDataValue?.address?.sub_district?.id) {
          this.subDistrictSelected = this.subDistricts.find(
            (x) => x.id === this.patienDataValue.address?.sub_district?.id
          );
          if (this.subDistrictSelected) {
            this.patient.address.sub_district_id = this.subDistrictSelected.id;
          }
        }
      }
      if (type === "father") {
        this.searchSubDistrictsFather = response.data.list;
        if (
          this.patienDataValue?.additional.parent.father.address?.sub_district?.id
        ) {
          this.subDistrictSelectedFather = this.subDistricts.find(
            (x) =>
              x.id ===
              this.patienDataValue.additional.parent.father.address?.sub_district?.id
          );
          if (this.subDistrictSelectedFather) {
            this.patient.additional.parent.father.address.sub_district.id = this.subDistrictSelectedFather.id;
          }
        }
      }
      if (type === "mother") {
        this.searchSubDistrictsMother = response.data.list;
        if (
          this.patienDataValue?.additional.parent.mother.address?.sub_district?.id
        ) {
          this.subDistrictSelectedMother = this.subDistricts.find(
            (x) =>
              x.id ===
              this.patienDataValue.additional.parent.mother.address.sub_district
                .id
          );
          if (this.subDistrictSelectedMother) {
            this.patient.additional.parent.mother.address.sub_district.id = this.subDistrictSelectedMother.id;
          }
        }
      }
    });
  }

  searchSubDistrict(event: any, type: string) {
    const input = event.target.value;
    const filterValueSubDistrict = input.toLowerCase();
    if (type === "patient") {
      this.searchSubDistricts = this.subDistricts.filter((option: any) =>
        option.name.toLowerCase().includes(filterValueSubDistrict)
      );
    }
    if (type === "father") {
      this.searchDistrictsFather = this.districts.filter((option: any) =>
        option.name.toLowerCase().includes(filterValueSubDistrict)
      );
    }
    if (type === "mother") {
      this.searchDistrictsMother = this.districts.filter((option: any) =>
        option.name.toLowerCase().includes(filterValueSubDistrict)
      );
    }
  }

  onChangeSubDistrict(event: any, type: string = "patient") {
    if (type === "patient") {
      this.patient.address.sub_district_id = event.value.id;
    }
    if (type === "father") {
      this.patient.additional.parent.father.address.sub_district = {
        id: event.value.id
      }
    }
    if (type === "mother") {
      this.patient.additional.parent.mother.address.sub_district = {
        id: event.value.id
      }
    }
  }

  defaultValueAdditional() {
    this.patient.additional = {
      finance: {
        income_monthly: {
          start: 0,
          end: 0,
        },
        financing: "",
      },
      parent: {
        father: {
          name: "",
          date_of_birth: "",
          jkn_number: "",
          nik: "",
          address: {
            province: {
              id: "",
            },
            district: {
              id: "",
            },
            sub_district: {
              id: "",
            },
            street: "",
          },
          blood_type: "",
          phone_number: "",
          education: "",
          job: "",
          health_facility: "",
          referred_health_facility: "",
        },
        mother: {
          name: "",
          date_of_birth: "",
          nik: "",
          jkn_number: "",
          address: {
            province: {
              id: "",
            },
            district: {
              id: "",
            },
            sub_district: {
              id: "",
            },
            street: "",
          },
          blood_type: "",
          phone_number: "",
          education: "",
          job: "",
          health_facility: "",
          referred_health_facility: "",
        },
      },
    };
  }

  onSubmit() {
    if (this.patient.phone_number) this.patient.phone_number = String(this.patient.phone_number);
    if (this.patient?.additional?.parent) {
      if (this.patient.additional.parent.father) this.patient.additional.parent.father.phone_number = String(this.patient.additional.parent.father.phone_number)
      if (this.patient.additional.parent.mother) this.patient.additional.parent.mother.phone_number = String(this.patient.additional.parent.father.phone_number)
    }
    this.patient.date_of_birth = this.datepipe.transform(this.patient.date_of_birth, "yyyy-MM-dd");
    this.patientService
      .updatePatient(this.patient, this.patienDataValue.id)
      .subscribe(() => {
        this.httpService.SuccessProcess("Berhasil Simpan Perubahan");
        window.location.reload();
      });
  }

  redirectAppointment() {
    this.router.navigate([`/janji-temu/lihat-janji`]);
  }
}
