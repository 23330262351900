<div>
  <!-- Left Sidebar -->
  <aside
    id="leftsidebar"
    class="sidebar side-fix"
    (mouseenter)="mouseHover($event)"
    (mouseleave)="mouseOut($event)"
  >
    <!-- Menu -->
    <div class="menu">
      <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
        <div class="logo">
          <img
            src="assets/images/banner/logo-kmnc.png"
            alt="logo-kmnc"
            class="logo-kmnc"
          />
        </div>
        <ul class="list">
          <!-- Top Most level menu -->
          <li
            *ngFor="let sidebarItem of sidebarItems"
            [routerLinkActive]="
              sidebarItem.submenu.length <= 0 ? 'active' : 'active-top'
            "
          >
            <div class="header" *ngIf="sidebarItem.groupTitle === true">
              {{ sidebarItem.title | translate }}
            </div>
            <a
              [routerLink]="
                sidebarItem.class === '' ? [sidebarItem.path] : null
              "
              *ngIf="!sidebarItem.groupTitle"
              [ngClass]="[sidebarItem.class]"
              (click)="callToggleMenu($event, sidebarItem.submenu.length)"
              class="menu-top"
            >
              <!-- <i [ngClass]="[sidebarItem.iconType]">{{ sidebarItem.icon }}</i> -->
              <img
                *ngIf="sidebarItem.submenu"
                class="filter-1"
                src="assets/images/icon-sidebar/{{ sidebarItem.iconSidebar }}"
                onerror="this.src=''"
                alt=""
                width="15px"
              />
              <!-- <img
                *ngIf="sidebarItem.submenu"
                src="assets/images/icon-sidebar/{{ sidebarItem.icon }}"
                onerror="this.src=''"
                alt=""
                width="15px"
              /> -->
              <span class="hide-menu"
                >{{ sidebarItem.title | translate }}
              </span>
              <span
                *ngIf="sidebarItem.badge != ''"
                [ngClass]="[sidebarItem.badgeClass]"
                >{{ sidebarItem.badge }}</span
              >
            </a>
            <!-- First level menu -->
            <ul
              class="ml-menu ml-menu-4"
              *ngIf="sidebarItem.submenu.length > 0"
            >
              <li
                *ngFor="let sidebarSubItem1 of sidebarItem.submenu"
                class="a"
                [routerLinkActive]="
                  sidebarSubItem1.submenu.length > 0 ? '' : 'active1'
                "
              >
                <a
                  [routerLink]="
                    sidebarSubItem1.submenu.length > 0
                      ? null
                      : [sidebarSubItem1.path]
                  "
                  (click)="
                    callToggleMenu($event, sidebarSubItem1.submenu.length)
                  "
                  [ngClass]="[sidebarSubItem1.class]"
                  class="menu-top1"
                >
                  <img
                    *ngIf="sidebarSubItem1.iconType"
                    src="assets/images/icon-sidebar/{{
                      sidebarSubItem1.iconType
                    }}"
                    class="filter1"
                    onerror="this.src=''"
                    alt=""
                    width="15px"
                    style="margin-right: 11px"
                  />
                  <img
                    *ngIf="!sidebarSubItem1.iconType"
                    src="assets/images/icon-sidebar/vector.svg"
                    class="filter1"
                    onerror="this.src=''"
                    alt=""
                    width="8px"
                    style="margin-right: 11px"
                  />

                  {{ sidebarSubItem1.title | translate }}
                </a>
                <!-- Second level menu -->
                <ul
                  class="ml-menu-2"
                  *ngIf="sidebarSubItem1.submenu.length > 0"
                >
                  <li
                    *ngFor="let sidebarSubItem2 of sidebarSubItem1.submenu"
                    [routerLinkActive]="
                      sidebarSubItem2.submenu.length > 0 ? '' : 'active1'
                    "
                  >
                    <a
                      [routerLink]="
                        sidebarSubItem2.submenu.length > 0
                          ? null
                          : [sidebarSubItem2.path]
                      "
                      (click)="
                        callToggleMenu($event, sidebarSubItem2.submenu.length)
                      "
                      [ngClass]="[sidebarSubItem2.class]"
                      class="menu-top1"
                    >
                      {{ sidebarSubItem2.title | translate }}
                    </a>
                    <!-- Third level menu -->
                    <ul
                      class="ml-menu-3"
                      *ngIf="sidebarSubItem2.submenu.length > 0"
                    >
                      <li
                        *ngFor="let sidebarSubItem3 of sidebarSubItem2.submenu"
                        [routerLinkActive]="
                          sidebarSubItem3.submenu.length > 0 ? '' : 'active1'
                        "
                      >
                        <a
                          [routerLink]="
                            sidebarSubItem3.submenu.length > 0
                              ? null
                              : [sidebarSubItem3.path]
                          "
                          (click)="
                            callToggleMenu(
                              $event,
                              sidebarSubItem3.submenu.length
                            )
                          "
                          [ngClass]="[sidebarSubItem3.class]"
                          class="menu-top1"
                        >
                          {{ sidebarSubItem3.title | translate }}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <ng-container *ngIf="routeDetailPatient">
          <app-personal-information-patient
            style="position: relative; top: 3rem"
          ></app-personal-information-patient>
        </ng-container>
      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
