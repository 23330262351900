<section class="content">
  <div class="content-block cont">
    <div class="logo">
      <img (click)="redirectAppointment()" src="assets/images/banner/logo-kmnc.png" alt="logo-kmnc" class="logo-kmnc" />
      <mat-icon (click)="clickBack()" class="back">keyboard_arrow_left</mat-icon>
    </div>
    <div class="flex-pasien-history">
      <div class="detail-patient">
        <app-personal-information-patient></app-personal-information-patient>
      </div>

      <div class="section-form">
        <div class="content-pemeriksaan">
          <div class="month-flex">
            <mat-form-field appearance="fill">
              <mat-label>Tanggal</mat-label>
              <input matInput [(ngModel)]="appointmentDate" readonly />
              <mat-datepicker-toggle matIconSuffix></mat-datepicker-toggle>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-tab-group [selectedIndex]="selectedIndex">
              <mat-tab class="bg-dark" [label]="formData.form?.name" *ngFor="let formData of appointmentForm">
                <section class="container card pt-3">
                  <div *ngIf="formTTVList?.length > 0" class="row">
                    <div *ngFor="let formTTV of formTTVList">
                      <div *ngIf="formTTV.id !== formData.form.id" class="col-xl-12 col-md-12 mt-3 mb-2">
                        <h4 class="header">{{formTTV.name}} (TTV)</h4>
                        <preview-template [formContainer]="formTTV.elements">
                        </preview-template>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button (click)="printForm(formData.form)" type="button" class="btn btn-outline-success">
                      PRINT
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mt-5 mb-5">
                      <preview-template class="mt-5 mb-5" [formContainer]="formData.form?.elements">
                      </preview-template>
                    </div>
                    <div class="col-md-12 d-flex justify-content-center mb-3">
                      <button class="btn btn-lg btn-block btn-primary" (click)="submitData(formData.form.id)">
                        {{ isAlreadySubmit ? 'Edit' : 'Submit' }}
                      </button>
                    </div>
                  </div>
                </section>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
