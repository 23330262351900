<!-- <div class="row">
  <div class="col-md-12 text-right">
    <button mat-raised-button type="button" class="btn btn-secondary btn-sm float-end mb-2">Download Pdf</button>
  </div>
</div> -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <ng-container *ngFor="let control of formContainer; let i = index">
            <ng-container [ngSwitch]="control.controlType" class="asw-full-width">
              <div [ngClass]="control.column">
                <ng-container *ngSwitchCase="'header'">
                  <asw-header [control]="control" [controlIndex]="i">
                  </asw-header>
                </ng-container>
                <ng-container *ngSwitchCase="'textfield'">
                  <asw-textfield [control]="control" [controlIndex]="i" (aswModelChange)="onSelectionChange($event)">
                  </asw-textfield>
                </ng-container>
                <ng-container *ngSwitchCase="'textarea'">
                  <asw-textarea [control]="control" [controlIndex]="i" (aswModelChange)="onSelectionChange($event)">
                  </asw-textarea>
                </ng-container>
                <ng-container *ngSwitchCase="'number'">
                  <asw-number [control]="control" [controlIndex]="i" [formControls]="formContainer">
                  </asw-number>
                </ng-container>
                <ng-container *ngSwitchCase="'calculation'">
                  <asw-calculation [control]="control" [controlIndex]="i" (aswModelChange)="onSelectionChange($event)">
                  </asw-calculation>
                </ng-container>
                <ng-container *ngSwitchCase="'select'">
                  <asw-select [control]="control" [controlIndex]="i" (selectionChange)="onSelectionChange($event)">
                  </asw-select>
                </ng-container>
                <ng-container *ngIf="!control.additional_type && !control.isICDX">
                  <ng-container *ngSwitchCase="'multi-select'">
                    <asw-multi-select [control]="control" [controlIndex]="i"
                      (selectionChange)="onSelectionChange($event)">
                    </asw-multi-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="control.additional_type === 'icd-x'|| control.isICDX">
                  <ng-container *ngSwitchCase="'multi-select'">
                    <form-element-icdx [control]="control" [controlIndex]="i" (dateChange)="onSelectionChange($event)">
                    </form-element-icdx>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="control.additional_type === 'item-package'">
                  <ng-container *ngSwitchCase="'multi-select'">
                    <form-element-item-package [control]="control" [controlIndex]="i"
                      (dateChange)="onSelectionChange($event)">
                    </form-element-item-package>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'checkbox'">
                  <asw-checkbox [control]="control" [controlIndex]="i" (selectionChange)="onSelectionChange($event)">
                  </asw-checkbox>
                </ng-container>
                <ng-container *ngSwitchCase="'datepicker'">
                  <asw-datepicker [control]="control" [controlIndex]="i" (dateChange)="onSelectionChange($event)">
                  </asw-datepicker>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
