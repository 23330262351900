import { Injectable } from "@angular/core";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "../models/filter.interface";

@Injectable()
export class BranchService extends UnsubscribeOnDestroyAdapter {
  constructor(
    private httpService: HttpService
  ) { super(); }

  listBranch(params: Filter = null) {
    return this.httpService.Get(`/v1/branch/list`, params);
  }
  createBranch(data: any) {
    return this.httpService.Post(`/v1/branch/create`, data);
  }
  updateBranch(id: any, data: any) {
    return this.httpService.Put(`/v1/branch/update/${id}`, data);
  }
  deleteBranch(id: any) {
    return this.httpService.Delete(`/v1/branch/delete`, { id: id });
  }
  detailBranch(id: any) {
    return this.httpService.Get(`/v1/branch/detail/${id}`);
  }

}
