import { Injectable } from "@angular/core";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "../models/filter.interface";

@Injectable()
export class PatientEHRService {
  constructor(
    private httpService: HttpService
  ) { }

  listPatientEHR(params: Filter = null) {
    return this.httpService.Get(`/v1/patient-ehr/list`, params);
  }
  detailPatientEHR(id: string) {
    return this.httpService.Get(`/v1/patient-ehr/detail/${id}`);
  }

}
