<section class="content">
  <div class="content-block cont">
    <div class="test">
      <img
        src="assets/images/banner/logo-kmnc.png"
        alt="logo-kmnc"
        class="logo-kmnc"
      />
      <mat-icon (click)="clickBack()" class="back"
        >keyboard_arrow_left</mat-icon
      >
    </div>

    <form-builder (previewClick)="previewForm($event)"></form-builder>
  </div>
</section>
