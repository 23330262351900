import { Injectable } from "@angular/core";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "../models/filter.interface";

@Injectable()
export class ICDXService extends UnsubscribeOnDestroyAdapter {
  constructor(
    private httpService: HttpService
  ) { super(); }

  listICDX(params: Filter = null) {
    return this.httpService.Get(`/v1/icd-x/list`, params);
  }
  createICDX(data: any) {
    return this.httpService.Post(`/v1/icd-x/create`, data);
  }
  updateICDX(id: any, data: any) {
    return this.httpService.Put(`/v1/icd-x/update/${id}`, data);
  }
  deleteICDX(id: any) {
    return this.httpService.Delete(`/v1/icd-x/delete`, { id: id });
  }
  detailICDX(id: any) {
    return this.httpService.Get(`/v1/icd-x/detail/${id}`);
  }

}
