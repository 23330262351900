import { HttpClient, HttpEventType, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private snackBarDurationInSeconds = 5;
  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private errorService: ErrorService
  ) { }

  ParamGet = (object: any) => {
    let params = '';
    let i = 0;
    for (const val in object) {
      if (object[val]) {
        if (i === 0) {
          params += `?${val}=${object[val]}`;
        } else {
          params += `&${val}=${object[val]}`;
        }
        i++;
      }
    }
    return params;
  }

  Get = (path: string, request: any = '') => {
    if (typeof request === 'object' && request !== null) {
      request = this.ParamGet(request);
    }
    const url = environment.apiUrl + path + (request || '');
    return this.http.get(url)
      .pipe(
        map((response: any) => {
          const dataResponse: any = {
            ...response
          }
          if (response.status === 200) {
            return dataResponse;
          }
          return dataResponse;
        }),
        catchError((e: Response) => this.errorService.handleError(e))
      );
  }

  Download = (path: string, request: any = '', fileName?: string) => {
    if (typeof request === 'object' && request !== null) {
      request = this.ParamGet(request);
    }
    const url = environment.apiUrl + path + (request || '');
    this.downloadFile(url, fileName).subscribe();
  }

  downloadFile(url: string, fileName: string = 'download.csv'): Observable<Blob> {
    this.InfoProcess(`Download in progress`);
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'events',
      reportProgress: true
    }).pipe(
      map(event => {
        if (event.type === HttpEventType.Response) {
          this.SuccessProcess(`Download Complete`);
          const file = new Blob([event.body], { type: 'text/csv' });
          const fileUrl = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(fileUrl);
          return file;
        }
      })
    );
  }

  Post = (path: string, body: any = {}) => {
    const url = environment.apiUrl + path;
    return this.http.post(url, body)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response;
          }
          return response;
        }),
        catchError((e: Response) => this.errorService.handleError(e))
      );
  }

  PostFormData = (path: string, body: any = {}) => {
    const url = environment.apiUrl + path;
    return this.http.post(url, body)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response;
          }
          return response;
        }),
        catchError((e: Response) => this.errorService.handleError(e))
      );
  }

  PutFormData = (path: string, body: any = {}) => {
    const url = environment.apiUrl + path;
    return this.http.put(url, body)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response;
          }
          return response;
        }),
        catchError((e: Response) => this.errorService.handleError(e))
      );
  }

  Put = (path: string, body: any) => {
    const url = environment.apiUrl + path;
    return this.http.put(url, body)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response;
          }
          return response;
        }),
        catchError((e: Response) => this.errorService.handleError(e))
      );
  }

  Patch = (path: string, body: any) => {
    const url = environment.apiUrl + path;
    return this.http.patch(url, body)
      .pipe(map((response: any) => {
        if (response.status === 200) {
          return response;
        }
        return response;
      }),
        catchError((e: Response) => this.errorService.handleError(e))
      );
  }

  Delete = (path: string, params: any) => {
    const url = environment.apiUrl + path + '/' + params.id;
    return this.http.delete(url)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response;
          }
          return response;
        }),
        catchError((e: Response) => this.errorService.handleError(e))
      );
  }

  SuccessProcess = (resp: any) => {
    this._snackBar.open(resp, "", {
      duration: 2000,
      verticalPosition: "bottom",
      horizontalPosition: "center",
      panelClass: "snackbar-success",
    });
  }

  InfoProcess = (resp: any) => {
    this._snackBar.open(resp, "", {
      duration: 2000,
      verticalPosition: "bottom",
      horizontalPosition: "center",
      panelClass: "snackbar-info",
    });
  }

}
