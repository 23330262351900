<section class="content">
  <div class="content-block cont">
    <div class="logo">
      <img (click)="clickBack()" src="assets/images/banner/logo-kmnc.png" alt="logo-kmnc" class="logo-kmnc" />
      <mat-icon (click)="clickBack()" class="back">keyboard_arrow_left</mat-icon>
    </div>
    <div class="flex-pasien-history">
      <div class="detail-patient">
        <app-personal-information-patient></app-personal-information-patient>
      </div>

      <div class="section-pemeriksaan">
        <div class="content-pemeriksaan">
          <div class="month-flex">
            <!-- <div class="btn-back" (click)="clickBack()">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </div> -->
            <mat-form-field appearance="fill">
              <mat-label>Tanggal Kunjungan</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate class="pc" [(ngModel)]="startVisitDate" placeholder="Start date" />
                <input matEndDate class="pc" [(ngModel)]="endLastVisitDate" placeholder="End date" />
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker>
                <mat-datepicker-actions>
                  <button mat-button (click)="resetDatePicker()" matDatepickerCancel>
                    Reset
                  </button>
                  <button mat-raised-button color="primary" (click)="loadData()" matDatepickerApply>
                    Selesai
                  </button>
                </mat-datepicker-actions>
              </mat-date-range-picker>
            </mat-form-field>
          </div>
          <div *ngFor="let appointment of appointmentData">
            <div class="chip-date">
              {{ appointment.date | date : "d MMMM y" }}
            </div>
            <div class="content-card">
              <div class="row">
                <div class="detail">
                  <button class="button" routerLink="/detail-pasien/form/{{
                      appointment.patient.id
                    }}/{{ appointment.id }}">
                    Detail
                  </button>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3"
                  *ngFor="let formData of validateRole(appointment.forms); let index = index">
                  <div class="card-height" [ngClass]="{
                      cards1: formData?.is_filled,
                      cards2: !formData?.is_filled
                    }">
                    <div class="title-1" [routerLink]="['/detail-pasien/form', appointment.patient.id, appointment.id]"
                      [queryParams]="{ index: index }">
                      <span class=" text-danger mt-0 mb-0">{{
                        formData?.form.is_required ? "*" : ""
                        }}</span>
                      {{ formData?.form.name | slice : 0 : 20 }}
                      <span *ngIf="formData?.form.name.length >= 20">...</span>
                    </div>
                    <div class="title-2">{{ formData?.updated_by_name }}</div>
                    <div class="title-3">
                      {{ formData?.updated_at | date : "HH:mm" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
