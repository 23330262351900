import { Role } from "src/app/core/models/role";
import { RouteInfo } from "../sidebar.metadata";

export const SUPERADMINROUTING: RouteInfo[] = [
  {
    path: "/dashboard/main",
    title: "MENUITEMS.DASHBOARD2.TEXT",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    iconSidebar: "dashboard.svg",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [Role.SUPERADMIN],
    submenu: [],
  },

  // Management on Super Admin
  {
    path: "",
    title: "MENUITEMS.MANAJEMEN.TEXT",
    iconType: "material-icons-two-tone",
    icon: "settings",
    iconSidebar: "manajemen.svg",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [Role.SUPERADMIN],
    submenu: [
      {
        path: "/admin-central/list",
        title: "MENUITEMS.ADMIN-CENTRAL.ITEMS.ALL",
        iconType: "icon-admin.svg",
        icon: "icon-admin.svg",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.SUPERADMIN],
        submenu: [],
      },
      {
        path: "/dashboard-admins/all-admins",
        title: "MENUITEMS.ADMINS.LIST.ALL-ADMIN",
        iconType: "icon-admin.svg",
        icon: "icon-admin.svg",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.SUPERADMIN],
        submenu: [],
      },
      {
        path: "/dashboard-management-executive/all-management-executive",
        title: "MENUITEMS.MANAGEMENT-EXECUTIVE.LIST.ALL-MANAGEMENT",
        iconType: "icon-management.svg",
        icon: "",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.SUPERADMIN],
        submenu: [],
      },
      {
        path: "/doctors/all-doctors",
        title: "MENUITEMS.DOCTORS.LIST.ALL-DOCTOR",
        iconType: "icon-doctor.svg",
        icon: "",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.SUPERADMIN],
        submenu: [],
      },
      {
        path: "/patients/all-patients",
        title: "MENUITEMS.PATIENTS.LIST.ALL-PATIENT",
        iconType: "icon-patient.svg",
        icon: "",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.SUPERADMIN],
        submenu: [],
      },
      {
        path: "/cabang/list-cabang",
        title: "MENUITEMS.CABANG.LIST.LIST-CABANG",
        iconType: "icon-cabang.svg",
        icon: "",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.SUPERADMIN],
        submenu: [],
      },
      {
        path: "/poli/list-poli",
        title: "MENUITEMS.POLI.LIST.LIST-POLI",
        iconType: "icon-poli.svg",
        icon: "",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.SUPERADMIN],
        submenu: [],
      },
      {
        path: "",
        title: "MENUITEMS.FORM.TEXT",
        iconType: "icon-form.svg",
        icon: "",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [Role.SUPERADMIN],
        submenu: [
          {
            path: "/form/list-form",
            title: "MENUITEMS.FORM.LIST.LIST-FORM",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [Role.SUPERADMIN],
            submenu: [],
          },
          {
            path: "/form/list-form",
            title: "MENUITEMS.FORM_ELEMENTS.TEXT",
            iconType: "",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [Role.SUPERADMIN],
            submenu: [
              {
                path: "/form/element/list-icdx",
                title: "MENUITEMS.FORM_ELEMENTS.LIST.ICDX",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [Role.SUPERADMIN],
                submenu: [],
              },
              {
                path: "/form/element/list-item-package",
                title: "MENUITEMS.FORM_ELEMENTS.LIST.ITEM-PACKAGE",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [Role.SUPERADMIN],
                submenu: [],
              },
            ],
          },
        ],
      },
    ],
  },
];
