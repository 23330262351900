import { Injectable } from "@angular/core";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "../models/filter.interface";

@Injectable()
export class SuperAdminService {
  constructor(
    private httpService: HttpService
  ) { }

  listSuperAdmin(params: Filter = null) {
    return this.httpService.Get(`/v1/super-admin/list`, params);
  }
  createSuperAdmin(data: any) {
    return this.httpService.Post(`/v1/super-admin/create`, data);
  }
  updateSuperAdmin(id: string, data: any) {
    return this.httpService.Put(`/v1/super-admin/update/${id}`, data);
  }
  deleteSuperAdmin(id: string) {
    return this.httpService.Delete(`/v1/super-admin/delete`, { id: id });
  }
  detailSuperAdmin(id: string) {
    return this.httpService.Get(`/v1/super-admin/detail/${id}`);
  }

}
