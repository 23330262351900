import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardCabangComponent } from "./admin/dashboard/cabang/dashboard-cabang.component";
import { JanjiTemuFormComponent } from "./admin/janji-temu/janji-temu-form/janji-temu-form.component";
import { LobbyComponent } from "./admin/janji-temu/lobby/lobby.component";
import { QueueComponent } from "./admin/janji-temu/queue/queue.component";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { SettingComponent } from "./layout/setting/setting.component";
import { CheckoutComponent } from "./transaction/checkout/checkout.component";
const routes: Routes = [
  { path: "", redirectTo: "auth/login", pathMatch: "full" },
  // {
  //   path: "appointment",
  //   component: JanjiTemuFormComponent,
  //   pathMatch: "full",
  // },
  // {
  //   path: "checkout/:id",
  //   component: CheckoutComponent,
  //   pathMatch: "full",
  // },
  // {
  //   path: "checkout",
  //   component: JanjiTemuFormComponent,
  //   pathMatch: "full",
  // },
  // {
  //   path: "queue",
  //   component: QueueComponent,
  //   pathMatch: "full",
  // },
  // {
  //   path: "lobby",
  //   component: LobbyComponent,
  //   pathMatch: "full",
  // },
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "dashboard-cabang",
    canActivate: [AuthGuard],
    component: DashboardCabangComponent,
  },
  {
    path: "antrian",
    loadChildren: () =>
      import("./antrian/antrian.module").then((m) => m.AntrianModule),
  },
  {
    path: "setting",
    canActivate: [AuthGuard],
    component: SettingComponent,
  },
  {
    path: "form-generator",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("../app/admin/form-generator/form-generator.module").then(
        (m) => m.FormGeneratorModule
      ),
  },
  { path: "**", component: Page404Component, pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
