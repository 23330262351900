<div class="patient-info">
  <div class="title-info">Informasi Personal</div>
  <div class="info">
    <div class="text-info-patient">Nama:</div>
    <div class="text-info-item">{{ patient.name }}</div>
  </div>
  <hr class="line-info" />
  <div class="info">
    <div class="text-info-patient">Rekam Medis:</div>
    <div class="text-info-item">
      {{ patient.medical_record ? patient.medical_record : "-" }}
    </div>
  </div>
  <hr class="line-info" />
  <div class="info">
    <div class="text-info-patient">Jenis Kelamin:</div>
    <div class="text-info-item">{{ patient.gender }}</div>
  </div>
  <hr class="line-info" />
  <div class="info">
    <div class="text-info-patient">Tanggal Lahir:</div>
    <div class="text-info-item">{{ patient.date_of_birth }}</div>
  </div>
  <hr class="line-info" />
  <div class="info">
    <div class="text-info-patient">Umur:</div>
    <div class="text-info-item">{{ patient.age }}</div>
  </div>
  <hr class="line-info" />
  <div class="info">
    <div class="text-info-patient">No Telepon:</div>
    <div class="text-info-item">{{ patient.phone_number }}</div>
  </div>
</div>
<div class="sidebar-identitas-riwayat">
  <div class="sidebar-item" [ngClass]="
      router.url.includes('detail-pasien/riwayat-pasien')
        ? 'active'
        : 'identitas-pasien'
    " (click)="toDirectPage('riwayat', this.idDetail)">
    <span>Riwayat Pemeriksaan</span>
  </div>
  <div class="sidebar-item" [ngClass]="
      router.url.includes('detail-pasien/identitas-pasien')
        ? 'active'
        : 'identitas-pasien'
    " (click)="toDirectPage('identitas', this.idDetail)">
    <span>Identitas Patient</span>
  </div>
  <div *ngIf="countFormEHR > 0" class="sidebar-item" [ngClass]="
      router.url.includes('detail-pasien/riwayat-ehr')
        ? 'active'
        : 'identitas-pasien'
    " (click)="toDirectPage('ehr', this.idDetail)">
    <span>Riwayat EHR</span>
  </div>
</div>
