import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'preview-template',
    templateUrl: './preview-template.html'
})
export class PreviewTemplate {
    @Input() formContainer: any[] = [];
    @Output() buttonClick = new EventEmitter<any[]>();
    @Output() ModelChange = new EventEmitter<any>();

    constructor() { }

    onSelectionChange(control: any): void {
        this.ModelChange.emit(control);
    }
}
