import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private readonly secretKey = 'kmnc-secret';
  encryptOption = {
    mode: CryptoJS.mode.ECB
  };

  constructor() { }

  encryptData(data: string): string {
    try {
      return CryptoJS.AES.encrypt(data, this.secretKey).toString();
    } catch (e) {
      throw e;
    }
  }

  decryptData(data: string): any {
    try {
      if (!data) return null;
      const bytes = CryptoJS.AES.decrypt(data, this.secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);;
    } catch (e) {
      throw e;
    }
  }

}
