import { Injectable } from "@angular/core";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "../models/filter.interface";

@Injectable()
export class AdminCentralService {
  constructor(
    private httpService: HttpService
  ) { }

  listAdminCentral(params: Filter = null) {
    return this.httpService.Get(`/v1/admin-central/list`, params);
  }
  createAdminCentral(data: any) {
    return this.httpService.Post(`/v1/admin-central/create`, data);
  }
  updateAdminCentral(id: any, data: any) {
    return this.httpService.Put(`/v1/admin-central/update/${id}`, data);
  }
  deleteAdminCentral(id: any) {
    return this.httpService.Delete(`/v1/admin-central/delete`, { id: id });
  }
  detailAdminCentral(id: any) {
    return this.httpService.Get(`/v1/admin-central/detail/${id}`);
  }

}
