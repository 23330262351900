import { appConfig } from "src/environments/environment";

export function getFileUrl(imageId?: string, access: "public" | "private" = "public") {
  if (imageId) {
    let fileUrl = `${appConfig.CMS_URL}/assets/${imageId}`;
    if (access === "private") fileUrl += `?access_token=${appConfig.CMS_STATIC_TOKEN}`;
    return fileUrl;
  }
  return "";
}
