<div class="dashboard-cabang">
  <div class="content-cabang">
    <div class="title-dashboard-cabang title-bold">
      Selamat Datang di Kosambi Maternal <br />and Children Center!
    </div>
    <div [ngClass]="branchs.length === 1 ? 'card-cabang-2' : 'card-cabang-1'">
      <div class="list-card-1" *ngFor="let branch of branchs">
        <div class="cards-dash-cabang-1" (click)="clickToDirect(branch.id)">
          <img
            class="image-dash-cabang-1"
            src="{{ getImage(branch.image_id) }}"
            alt=""
            onerror="this.src='assets/images/banner/logo-kmnc.png'"
            height="334px"
            width="352px"
          />
        </div>
        <div class="title-card-cabang-1">{{ branch.name }}</div>
      </div>
    </div>
  </div>
</div>
