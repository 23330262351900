import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private snackBar: MatSnackBar) { }

  handleError = (resp: any) => {
    this.snackBar.open(resp, "", {
      duration: 4000,
      verticalPosition: "bottom",
      horizontalPosition: "center",
      panelClass: "snackbar-danger",
    });
    return throwError(() => resp);
  }

}
