import { Injectable } from "@angular/core";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { HttpService } from "src/app/core/service/http.service";
import { Filter } from "src/app/core/models/filter.interface";
import { Patient } from "src/app/core/models/patient.model";
@Injectable()
export class PatientService extends UnsubscribeOnDestroyAdapter {
  dialogData: any;

  constructor(private httpService: HttpService) {
    super();
  }

  getAllPatients(params: any) {
    return this.httpService.Get("/v1/patient/list", params);
  }

  getDetailPatient(id: any = null) {
    return this.httpService.Get(`/v1/patient/detail/${id}`);
  }

  addPatient(patient: Patient): void {
    this.dialogData = patient;
  }

  updatePatient(data: any, id: string) {
    return this.httpService.Put(`/v1/patient/update/${id}`, data);
  }

  deletePatient(id: any) {
    return this.httpService.Delete("/v1/patient/delete", { id: id });
  }

  downloadPatient(params: Filter) {
    return this.httpService.Download(
      "/v1/patient/download",
      params,
      "patient.csv"
    );
  }

  checkAntrianPatient(data: any) {
    return this.httpService.Post("/v1/appointment/patient-queue", data);
  }

  validatePatient(data: any) {
    return this.httpService.Post("/v1/patient/validate", data);
  }
  getDetailAppointment(params: any = null) {
    return this.httpService.Get(`/v1/appointment/detail/${params}`);
  }
}
