import { Router, NavigationEnd } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { AuthService } from "src/app/core/service/auth.service";
import { Role } from "src/app/core/models/role";
import { SUPERADMINROUTING } from "./routing/super-admin.routing";
import { MANAGEMENTEXECUTIVEROUTING } from "./routing/management-executive.routing";
import { ADMINROUTING } from "./routing/admin.routing";
import { DOCTORROUTING } from "./routing/doctor.routing";
import { RouteInfo } from "./sidebar.metadata";
import { ADMINCENTRALROUTING } from "./routing/admin-central.routing";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: RouteInfo[] = [];
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: any;
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;
  role: string;
  routeDetailPatient = false;

  dataService: [];

  jobRoles: string;
  dataSubmenu: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router
  ) {
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, "overlay-open");
      }
    });
  }
  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target.closest("li"))) {
      this.renderer.removeClass(this.document.body, "overlay-open");
    }
  }

  callToggleMenu(event: any, length: any) {
    if (length > 0) {
      const parentElement = event.target.closest("li");
      const activeClass = parentElement.classList.contains("active");
      if (activeClass) {
        this.renderer.removeClass(parentElement, "active");
      } else {
        this.renderer.addClass(parentElement, "active");
      }
    } else {
      const parentElement = event.target.closest("li");
      this.renderer.addClass(parentElement, "active");
    }
  }

  async ngOnInit() {
    const urlDetail = this.router.routerState.snapshot.url;
    const allowedURL: string[] = [
      "detail-pasien/detail-pasient",
      "detail-pasien/identitas-pasien",
      "detail-pasien/riwayat-pasien",
    ];
    if (allowedURL.includes(urlDetail)) {
      this.router.navigate(["detail-pasien/identitas-pasien"]);
      this.routeDetailPatient = true;
    } else {
      this.routeDetailPatient = false;
    }
    const user = await this.authService.getMe();
    const sidebarItem = [];
    switch (user.role) {
      case Role.SUPERADMIN:
        sidebarItem.push(...SUPERADMINROUTING);
        break;
      case Role.MANAGEMENTEXECUTIVE:
        sidebarItem.push(...MANAGEMENTEXECUTIVEROUTING);
        break;
      case Role.ADMIN:
        sidebarItem.push(...ADMINROUTING);
        break;
      case Role.DOCTOR:
        sidebarItem.push(...DOCTORROUTING);
        break;
      case Role.ADMINCENTRAL:
        sidebarItem.push(...ADMINCENTRALROUTING);
        break;
      default:
        break;
    }
    this.sidebarItems = await this.getSideBarMenu(sidebarItem);
    this.jobRoles = user.role;
    if (user.role === Role.SUPERADMIN) this.userType = Role.SUPERADMIN;
    if (user.role === Role.ADMIN) this.userType = Role.ADMIN;
    if (user.role === Role.DOCTOR) this.userType = Role.DOCTOR;
    if (user.role === Role.MANAGEMENTEXECUTIVE)
      this.userType = Role.MANAGEMENTEXECUTIVE;
    if (user.role === Role.ADMINCENTRAL) this.userType = Role.ADMINCENTRAL;
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

  async getSideBarMenu(routes: RouteInfo[]) {
    const user = await this.authService.getMe();
    const dataSidebar = [];
    routes.forEach((value, i) => {
      if (value.role.includes(user.role)) {
        const dataSidebarSub = [];
        value.submenu.forEach((value2, index2) => {
          if (value2.role.includes(user.role)) {
            dataSidebarSub.push(value2);
            const dataSideBarSubChild = [];
            value2.submenu.forEach((value3, index3) => {
              if (value3.role.includes(user.role)) {
                dataSideBarSubChild.push(value3);
              }
            });
            value2.submenu = dataSideBarSubChild;
          }
        });
        value.submenu = dataSidebarSub;
        dataSidebar.push(value);
      }
    });
    return dataSidebar;
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }
  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, "ls-closed");
    } else {
      this.renderer.removeClass(this.document.body, "ls-closed");
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("submenu-closed")) {
      this.renderer.addClass(this.document.body, "side-closed-hover");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("side-closed-hover")) {
      this.renderer.removeClass(this.document.body, "side-closed-hover");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
  toDirectPage(item) {
    if (item === "identitas") {
      this.router.navigate(["patients/detail-pasien/identitas-pasien"]);
    } else {
      this.router.navigate(["patients/detail-pasien/riwayat-pasien"]);
    }
  }

  imageSource(image: string) {
    return image ? `assets/images/icon-sidebar/${image}` : "";
  }
}
