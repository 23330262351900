<ng-container>
  <mat-form-field [appearance]="control.style" class="asw-mat-form-field {{control.customClass}}">
    <mat-label>{{control.label}}</mat-label>
    <mat-select [placeholder]="control.label" [(ngModel)]="control.value" [id]="control.id" #input="ngModel" multiple
      (selectionChange)="onSelectionChange(control)" [required]="control.isRequired"
      [disabled]="control.isDisabled ? true : false">
      <mat-option>
        <ngx-mat-select-search noEntriesFoundLabel="item package tidak ditemukan"
          placeholderLabel="Cari Item Package" (input)="searchItemPackage($event) ">
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let itemPackage of itemPackages" [value]="itemPackage.code">
        {{ itemPackage.name }}
      </mat-option>
    </mat-select>
    <mat-error class="asw-mat-error" *ngIf="input.invalid && (input.dirty || input.touched)">
      <ng-container class="asw-mat-error" *ngIf="input?.errors?.required">
        {{control.label}} is required
      </ng-container>
    </mat-error>
  </mat-form-field>
  <ng-template [ngIf]="isPreviewTemplate">
    <div class="row">
      <div class="col-md-12">
        <button mat-icon-button type="button" matTooltip="Duplicate" [matTooltipPosition]="'below'"
          (click)="duplicateItemPackageControl(control)">
          <mat-icon>content_copy</mat-icon>
        </button>
        <button mat-icon-button type="button" matTooltip="Delete" [matTooltipPosition]="'below'"
          (click)="deleteItemPackageDialog(control, controlIndex)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
